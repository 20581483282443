
<div class="left-sidebar-col h-100">
    <div class="left-sidebar">

        <div class="left-profile">
            <div class="left-side">
                <img class="profile-pic" src="assets/profile-pic.png">
            </div>
            <div class="right-side">
                <div class="welcome-back">Welcome Back,</div>
                <div class="boeing-editor bold-text">AIRLINE</div>
            </div>
        </div>
        <div>
            <mat-form-field appearance="fill" class="model-dropdown btl-dd">
                <mat-label class="label-color">Entity</mat-label>
                <mat-select>
                    <mat-option value="value1">Alaska Airline</mat-option>
                    <mat-option value="value2">American Airline</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" class="model-dropdown btl-dd">
                <mat-label class="label-color">Distribution</mat-label>
                <mat-select>
                    <mat-option value="value1">Direct</mat-option>
                    <mat-option value="value2">Boeing Admin</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" class="model-dropdown btl-dd">
                <mat-label class="label-color">Area</mat-label>
                <mat-select>
                    <mat-option value="value1">Flight</mat-option>
                    <mat-option value="value2">Flight-Draft</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" class="model-dropdown btl-dd">
                <mat-label class="label-color">Model</mat-label>
                <mat-select>
                    <mat-option value="value1">737 Max</mat-option>
                    <mat-option value="value2">737 Max 8</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</div>