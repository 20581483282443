import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedDataService } from 'src/app/services/shared-data.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  title = 'btl-video-service-ui';
  constructor(
    private _router: Router,
    private sharedDataService: SharedDataService,
  ) {}

  ngOnInit(): void {
  } 
}

