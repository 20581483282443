import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { INgxLoadingConfig, ngxLoadingAnimationTypes } from 'ngx-loading';
import { SharedDataService } from 'src/app/services/shared-data.service';
@Component({
  selector: 'app-upload-progress',
  templateUrl: './upload-progress.component.html',
  styleUrls: ['./upload-progress.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush

})
export class UploadProgressComponent implements OnInit {

  progressList: any = [];

  show: boolean = true;
  zIndex: number = 1000;
  configLoading: INgxLoadingConfig = {};
  loadMore: boolean = true;
  loadMoreMode: boolean = true;
  loadForUpload: boolean = true;
  backdropBackgroundColour: string = '#F0F7FB';
  constructor(private sharedDataService: SharedDataService) { }

  ngOnInit(): void {

    this.initConfig();

    this.sharedDataService.progressList$.subscribe(progressList => {
      this.progressList = progressList;
    })
  }

  initConfig() {
    this.configLoading = {
      animationType: ngxLoadingAnimationTypes.circle,
      backdropBackgroundColour: this.loadMoreMode ? 'rgba(0,0,0,0)' : (this.backdropBackgroundColour ? this.backdropBackgroundColour : 'rgba(0,0,0,0.8)'),
      primaryColour: this.loadMoreMode ? '#475F74' : '#384C5E',
      secondaryColour: this.loadMoreMode ? '#ccc' : '#0088CB'
    };
  }

}
