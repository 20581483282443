import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable,from,of } from 'rxjs';
import { catchError, delay, map } from 'rxjs/operators'; 
import { environment } from 'src/environments/environment';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private httpHeaders = new HttpHeaders()
  .set('Ocp-Apim-Subscription-Key', environment.btlVsSubscriptionKey);

  constructor(private http: HttpClient) { }

  isLoggedIn(): Observable<boolean> {
    console.log("is LoggedIn Start");
    return this.http.get('/api/check-access').pipe(
      map((response: any) => {
          console.log("NODE JS API Check-access response:", response);
          if (response?.statusMessage === "authorized") {                      
            window.sessionStorage.setItem("jwt_token", response?.jwtToken);
            return true;
          }
          return false;
      }),
      catchError(() => {
          return of(false);
      })
    );
  }

  getAuthToken(): Observable<string> {
    return this.http.get('/api/check-access').pipe(
      map((response: any) => {
          console.log("Response::", response);
          if(response?.statusMessage === "authorized") {  
            return response?.jwtToken;
          }
      })
    );
  }

  getUserDetails(){
    console.log("Get User dewtails:",window.sessionStorage)
    let token = window.sessionStorage.getItem("jwt_token");
    console.log("Token from session storage:",token)
    return this.http.get<any>(`${environment.backendBaseUrl}/users?token=${token}`, {
      headers: this.httpHeaders,
    });
  }
}
