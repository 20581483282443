import { Routes } from '@angular/router';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { VideoListComponentComponent } from './components/video-list-component/video-list-component.component';

const routes: Routes = [
  {
    path: '',
    component: VideoListComponentComponent,
    children: [
    ]
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent
  }
];

export { routes };
