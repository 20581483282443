<div *ngIf="showBanner">
    <lib-status-message></lib-status-message>
</div>
<div>
    <div style="margin-left: 20px;">
        <div>
            <div class="header-div" style="margin-left:20px">
                <div class="header"><strong>Replace Video</strong></div>
                <div class="cross">
                    <span>
                        <mat-icon class="btn-icon" (click)="cancelUpload()">highlight_off</mat-icon>
                    </span>
                </div>
            </div>
            <div class="upload-div" style="margin-left:20px">
                <span class="mat-button-wrapper">
                    <button mat-raised-button class="upload-btn" [disabled]="disableUploadBtn" (click)="fileInput.click()">
                        <span class="mat-button-wrapper">
                            <mat-icon class="btn-icon">file_upload</mat-icon>
                            BROWSE
                        </span>
                    </button>
                    <input type="file" #fileInput style="display: none" (change)="handleFileSelect($event)">
                </span>
                <div *ngIf="newFileName" class="new-file-name">
                    New FileName: {{ newFileName }}
                </div>
            </div>
            <div class="video-metadata">
                <div class="mx-2">
                    <div class="artifact-information">VIDEO DETAILS </div>
                    <div class="metadata-box" style="max-height: 500px; overflow-y: auto;">
                        <div *ngFor="let key of metadataOrder">
                            <div class="metadata-entry" *ngIf="videoMetaData[key]">
                                <div>
                                    <div class="input-field-task input-field example-box">
                                        <mat-form-field appearance="fill" class="form-input">
                                            <mat-label class="input-label large-label">{{key}}</mat-label>
                                            <input matInput [(ngModel)]="videoMetaData[key]" placeholder="key" class="editable-field" [disabled]="true" />
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="output">
            <div mat-dialog-actions class="buttons">
                <button class="btn-cancel mx-2" (click)="cancelUpload()">Cancel</button>
                <button mat-raised-button class="save-btn">
                    SAVE
                </button>
            </div>
        </div>
    </div>
</div>