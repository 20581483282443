<style>
    .spinner-wrapper {
      position: fixed;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.75);
      z-index: 998;
    }
  
    .spinner-wrapper mat-spinner {
      width: 2rem;
      height: 2rem;
    }
  
    .spinner-wrapper .loading-text {
      font-size: 20px;
      color: white;
    }
  </style>
  
  <div class="spinner-wrapper">
    <mat-spinner> </mat-spinner>
    <div class="loading-text">Loading...</div>
  </div>
  