import { Component, OnInit, ViewChild } from '@angular/core';
import { VideoPlayerDialogComponent } from 'src/app/components/video-player-dialog/video-player-dialog.component';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { VideoService } from 'src/app/services/video-service';
import { SpinnerOverlayService } from 'src/app/services/spinner-overlay-service';
import { Video } from 'src/app/models/videos.model';
import { BtlSharedLibraryService } from 'btl-shared-library';
import { ACCEPTABLE_TYPES } from 'src/app/constants/constants';
import { INgxLoadingConfig, ngxLoadingAnimationTypes } from 'ngx-loading';
import { Router } from '@angular/router';
import { FileUploadDialogComponent } from '../file-upload-dialog/file-upload-dialog.component';
import { DownloadProgress } from 'src/app/models/access.model';
import { HttpEventType } from '@angular/common/http';
import { saveAs as importedSaveAs } from 'file-saver';
import { MatMenuTrigger } from '@angular/material/menu';
import { formatDate, removeProgressItem, sortVideosByCreatedDate, updateProgressItem } from 'src/app/utils/video-utils';
import { ProgressItem } from 'src/app/models/access.model';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DownloadMetadata } from '../../models/download-meta-data.model';
import { MetadataComponent } from '../metadata/metadata.component';
import { PocDynamicVideoPlayerComponent } from '../poc-dynamic-video-player/poc-dynamic-video-player.component';


@Component({
  selector: 'app-stream-processed-videos-demo',
  templateUrl: './stream-processed-videos-demo.component.html',
  styleUrls: ['./stream-processed-videos-demo.component.scss']
})
export class StreamProcessedVideosDemoComponent implements OnInit {
  dataSource: any;
  videos: any[] = [];
  ascendingOrder: boolean = true;
  latestCreatedVideos: boolean = true;
  columns: any[] = ['filename', 'group', 'size', 'createdDate', 'actions'];
  dataLoad = false;
  pageSize = 7;
  showPages = 2;
  pageLength = this.pageSize * (this.showPages - 1);
  filterValue = "";

  showUploadBanner: boolean = false;
  loaded!: string;
  total?: string;
  percentDone!: number;
  downloadProgress: any;
  downloadId: string | undefined;
  progressItems: ProgressItem[] = []
  downloading: boolean = false;
  downloadMetadata: DownloadMetadata | undefined;
  videoDownloadStates: Map<string, boolean> = new Map<string, boolean>();
  downloadMetadataMap: Map<string, DownloadMetadata> = new Map<string, DownloadMetadata>(); // Map to store download metadata
  configLoading: INgxLoadingConfig = {};

  constructor(public dialog: MatDialog,
    private router: Router,
    private sharedDataService: SharedDataService,
    private videoService: VideoService,
    private spinnerOverlayService: SpinnerOverlayService,
    private btlSharedLibraryService: BtlSharedLibraryService,
  ) { }

  @ViewChild('menuTrigger')
  menuTrigger!: MatMenuTrigger;
  @ViewChild(MatSort) sort?: MatSort;
  @ViewChild(MatPaginator) paginator?: MatPaginator;

  ngOnInit(): void {
    this.getAllVideos();
    this.sharedDataService.showUploadBanner$.subscribe(showUploadBanner => {
      this.showUploadBanner = showUploadBanner;
    })

    this.sharedDataService.filterValue$.subscribe(filter => {
      this.filterValue = filter
      if (this.filterValue != null) {
        this.searchArtifacts();
      }
    })

    this.updateDataSource(this.videos);
  }


  getAllVideos(): void {
    let data = [
        {
          "contentKey": "TS_TSVIDEO_CXCULH7D:1.1",
          "metadata": {
            "description": "How Boeing Builds a 737 Plane in Just 9 Days",
            "streamingUrl": "",
            "title": "New Title"
          },
          "cmsObjectInfo": {
            "docObjectId": "ec5ee5ec-0b23-4c29-9297-2521fa63eacb",
            "documentVer": 2,
            "docType": "TSVIDEO",
            "docName": "How Boeing Builds a 737 Plane in Just 9 Days _ On Location(1).mp4",
            "createdBy": "anonymous",
            "createdDate": "2024-04-10T16:01:20.593+00:00",
            "modifiedBy": "anonymous",
            "modifiedDate": "2024-04-10T16:01:20.593+00:00",
            "size": "19924068"
          }
        },
        {
          "contentKey": "TS_TSVIDEO_6T172QO4:1.0",
          "metadata": {
              "description": "Test",
              "streamingUrl": "",
              "title": "Test"
          },
          "cmsObjectInfo": {
              "docName": "vm-test.mp4",
              "createdDate": "2024-02-04T11:03:07.297+00:00",
              "size": 753883,
              "createdBy": "anonymous",
              "docObjectId": "0f02d729-ea2c-4756-acf3-19d6b2c6afa8",
              "docType": "TSVIDEO",
              "modifiedDate": "2024-02-04T11:03:07.297+00:00",
              "modifiedBy": "anonymous",
              "documentVer": 1
          }
        },
        {
          "contentKey": "TS_TSVIDEO_GU4AFNDA:1.0",
          "metadata": {
              "title": "Test catalyst streaming with Fragmented Video",
              "description": "Fragmented video Test",
              "streamingUrl": "",
              "model": null
          },
          "cmsObjectInfo": {
              "docObjectId": "ecb5a5ac-5d3e-4ef2-8158-31151041a1fa",
              "documentVer": 1,
              "docType": null,
              "docName": "Boeing 777X First Flight Framgmented_52.mp4",
              "createdBy": "anonymous",
              "createdDate": "2024-06-22T17:31:28.299+00:00",
              "modifiedBy": "anonymous",
              "modifiedDate": "2024-06-22T17:31:28.299+00:00",
              "size": null
          }
        }
      ]
      this.videos= data;
      this.updateDataSource(this.videos);
      this.sharedDataService.updateVideosList(this.videos);
      this.dataLoad = true;
  }

  updateDataSource(videos: any) {
    this.dataSource = new MatTableDataSource<any>(videos);
    this.pageLength = this.videos.length;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataLoad = true
  }

  test(_t47: any) {
    console.log('Method not implemented.');
  }

  toggleDateOrder() {
    this.latestCreatedVideos = !this.latestCreatedVideos;
    this.videos = sortVideosByCreatedDate(this.videos, this.latestCreatedVideos)
    this.sharedDataService.updateVideosList(this.videos);
  }


  pageChangeEvent(event: PageEvent) {
    this.dataSource.paginator = this.paginator;
  }

  formatDate(date: string) {
    return formatDate(date)
  }

  openVideoDialog(video: any): void {
    this.spinnerOverlayService.show();
    
    // Call the rendition API
    this.videoService.getAllRenditions(video.contentKey).subscribe(
      (renditionsResponse: any) => {
        // Call the streaming URL API inside the rendition API's success callback
        this.videoService.getCmsSassStreamUrl(video.contentKey).subscribe(
          (streamingUrlResponse: any) => {
            // Decode and parse the streaming URL response
            let encodedData = streamingUrlResponse.data;
            const decodedString = atob(encodedData);
            const decodedJsonResponse = JSON.parse(decodedString);
            const streamingUrl = decodedJsonResponse.data.documentURL;
  
            // Set the streaming URL property in the video object
            video.streamingUrl = streamingUrl;
            video.renditions = renditionsResponse.data.renditions;
            console.log("VIDEO:",video)
            
            // Pass the video data to the dialog component
            this.spinnerOverlayService.hide();
            const dialogRef = this.dialog.open(PocDynamicVideoPlayerComponent, {
              width: '70%',
              data: { video }
            });
          },
          (streamingUrlError) => {
            console.log("Error fetching streaming URL:", streamingUrlError);
            this.spinnerOverlayService.hide();
            const errorMessage = streamingUrlError.error && streamingUrlError.error.message ? streamingUrlError.error.message : "An error occurred while fetching stream URL.";
            // Pass the video data to the dialog component even if streaming URL API fails
            const dialogRef = this.dialog.open(PocDynamicVideoPlayerComponent, {
              width: '70%',
              data: { video, renditions: renditionsResponse.data }
            });
          }
        );
      },
      (renditionsError) => {
        console.log("Error fetching renditions:", renditionsError);
        this.spinnerOverlayService.hide();
        const errorMessage = renditionsError.error && renditionsError.error.message ? renditionsError.error.message : "An error occurred while fetching renditions.";
        // Pass the video data to the dialog component even if renditions API fails
        const dialogRef = this.dialog.open(PocDynamicVideoPlayerComponent, {
          width: '70%',
          data: { video }
        });
      }
    );
  }
  

  downloadDocument(video: any) {
    this.closeMenu();
    this.loaded = "";
    this.total = "";
    this.percentDone = 0;
    window.scroll(0, 0);

    // Set download state for this video to true
    this.videoDownloadStates.set(video.contentKey, true);

    // Initialize progress for this download
    let videoMetadata = {
      fileName: video.cmsObjectInfo.docName,
      contentKey: video.contentKey
    }

    this.downloadMetadataMap.set(video.cmsObjectInfo.docObjectId, {
      fileName: video.cmsObjectInfo.docName,
      progress: 0,
      progressText: 'Downloading...',
      totalSize: '',
      remainingSize: ''
    });

    this.downloading = true;
    // Function to update download progress
    const updateDownloadProgress = (progress: number, totalSize: string, remainingSize: string) => {
      const downloadMetadata = this.downloadMetadataMap.get(video.cmsObjectInfo.docObjectId);
      if (downloadMetadata) {
        downloadMetadata.progress = progress;
        downloadMetadata.totalSize = totalSize;
        downloadMetadata.remainingSize = remainingSize;
      }
    };
    // Initialize progress item
    // let progressItem = new ProgressItem();
    // progressItem.uploading = true;
    // progressItem.progress = 1;
    // progressItem.uploadMetaData = videoMetadata;
    // progressItem.progressText = "Downloading...";
    // this.progressItems.push(progressItem);
    // this.sharedDataService.updateProgressList(this.progressItems);

    this.sharedDataService.updateUploadMetaData(videoMetadata);
    video.subscription = this.videoService.downloadArtifact('TSVIDEO', video.contentKey).subscribe({
      next: event => {
        if (event.type === HttpEventType.DownloadProgress) {
          if (event.total && event.total > 0) {
            this.percentDone = Math.round(100 * event.loaded / event.total);
            this.total = this.formatBytes(event.total);
            let currentDownloadingState = event.loaded <= event.total;
            const totalSize = event.total ? this.formatBytes(event.total) : 'Unknown';
            const remainingSize = event.total && event.loaded ? this.formatBytes(event.total - event.loaded) : 'Unknown';
            const progress = Math.round(100 * event.loaded / event.total);
            updateDownloadProgress(progress, totalSize, remainingSize);

          }
          this.loaded = this.formatBytes(event.loaded);
        } else if (event.type === HttpEventType.Response) {
          this.videoDownloadStates.set(video.contentKey, false);
          this.downloading = false;
          this.sharedDataService.updateShowUploadBanner(true);
          this.btlSharedLibraryService.updateStatusMessage(true, `${video.cmsObjectInfo.docName} downloaded successfully`, true);
          importedSaveAs(event.body, video.cmsObjectInfo.docName);
          //this.progressItems = removeProgressItem(this.progressItems, video.contentKey);

          // Remove download metadata for this video when download completes
          this.downloadMetadataMap.delete(video.cmsObjectInfo.docObjectId);
        }
      },
      error: error => {
        console.log('Download Artifact Error', error);
        this.videoDownloadStates.set(video.contentKey, false);
        this.downloading = false;
        const downloadMetadata = this.downloadMetadataMap.get(video.cmsObjectInfo.docObjectId);
        if (downloadMetadata) {
          downloadMetadata.progressText = 'Download failed';
        }
        //this.progressItems = removeProgressItem(this.progressItems, video.contentKey);
        this.sharedDataService.updateShowUploadBanner(true);
        this.btlSharedLibraryService.updateStatusMessage(true, error.message, false);
        // Remove download metadata for this video when download fails
        this.downloadMetadataMap.delete(video.cmsObjectInfo.docObjectId);
      }
    });
  }

  closeMenu() {
    if (this.menuTrigger && this.menuTrigger.menuOpen) {
      this.menuTrigger.closeMenu();
    }
  }


  searchArtifacts() {
    this.dataSource.filter = this.filterValue.trim().toLowerCase();
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const searchTerm = filter;
      return data.metadata.description.toLowerCase().includes(searchTerm) ||
        data.metadata.description.toLowerCase().includes(searchTerm) ||
        data.cmsObjectInfo.docName.toLowerCase().includes(searchTerm) ||
        data.cmsObjectInfo.docObjectId.toLowerCase().includes(searchTerm) ||
        data.cmsObjectInfo.createdDate.toLowerCase().includes(searchTerm) ||
        data.contentKey.toLowerCase().includes(searchTerm);
    };
    this.pageLength = this.dataSource.filteredData.length;
    this.showPages = this.dataSource.filteredData.length <= 5 ? 1 : this.dataSource.filteredData.length > 5 && this.dataSource.filteredData.length <= 10 ? 2 : 3;
  }

  formatBytes(bytes: any, decimals = 2) {
    if (!+bytes) return '0 Byte';
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  openMetadataDialog(row: any, type: string): void {
    console.log("Open File Upload Dialog");
    console.log("window.innerHeight : ", window.innerHeight);
    let heightRatio = 0;
    let widthRatio = 0;
    if (window.innerHeight > 900 && type === 'info') {
      heightRatio = 6;
      widthRatio = 2;
    } else if (window.innerHeight < 900 && type === 'info') {
      heightRatio = 10;
      widthRatio = 2.7;
    }

    if (window.innerHeight > 900 && type === 'edit') {
      heightRatio = 7;
      widthRatio = 2;
    } else if (window.innerHeight < 900 && type === 'edit') {
      heightRatio = 12;
      widthRatio = 2.7;
    }
    const windowHeight = (window.innerHeight * heightRatio) / 100;
    const windowWidth = (window.innerWidth * widthRatio) / 100;
    let data = {
      "docType": row.cmsObjectInfo.docType,
      "documentid": row.contentKey,
      "type": type
    }
    const dialogRef = this.dialog.open(MetadataComponent,
      {
        width: `${windowWidth}%`,
        height: `${windowHeight}%`,
        autoFocus: false,
        data: data
      });
    dialogRef.afterClosed().subscribe((result: any) => {
    });
  }

  handleDelete(row : any){
    this.spinnerOverlayService.show()
    this.videoService.deleteVideo(row.cmsObjectInfo.docType,row.contentKey).subscribe({
      next : (response) => {
        console.log('response is ',response)
        this.videoService.getAllVideosFromCms('tsvideo').subscribe({
          next: (response) => {
            this.videos = response.data.data;
            this.toggleDateOrder();
            this.updateDataSource(this.videos);
            this.spinnerOverlayService.hide();
            this.sharedDataService.updateVideosList(this.videos);
            this.sharedDataService.updateShowUploadBanner(true);
            this.btlSharedLibraryService.updateStatusMessage(true,`${row.cmsObjectInfo.docName} has been deleted successfully`,true)
          },
          error: (error) => {
            console.error('Error fetching videos:', error);
            this.spinnerOverlayService.hide()
          }
        });
        this.dataLoad = true;
      },
      error : (e) => {
        this.spinnerOverlayService.hide();
        this.sharedDataService.updateShowUploadBanner(true);
        this.btlSharedLibraryService.updateStatusMessage(true,`Failure to delete ${row.cmsObjectInfo.docName} . ${e.error.message}`,false)

      }
    }
    );
    
  }

  stopDownload(video: any) {
    this.videoDownloadStates.set(video.contentKey, false);
    video.subscription.unsubscribe();
  }

}
