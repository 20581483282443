<div class="player" style="display: flex;justify-content: space-around;flex-wrap: wrap">
    <div class="CMS-storage-sass-url">
      <p class="player-title">Streaming MP4 File with CMS SASS URL</p>
      <video id="cms-storage-sass-player" class="video-js" controls preload="auto" width="640" height="360" data-setup='{}'>
      <source src="https://asset-service-cdn.azureedge.net/artifact-service/Ramping up for delivery.mp4">
      <track
        label="English"
        kind="subtitles"
        srclang="en"
        src="https://asset-service-cdn.azureedge.net/artifact-service/Ramping up for delivery.vtt"
      />
      </video>
    </div>
  
    <div class="azure-cdn-mp4">
        <p class="player-title">Streaming MP4 File with Storage AZURE CDN </p>
        <video id="azure-cdn-video" class="video-js" controls preload="auto" width="640" height="360" data-setup='{}'>
            <source src="https://asset-service-cdn.azureedge.net/artifact-service/Experience Rollout from Boeing Starliner's Perspective.mp4">
            <!-- Fallback for non-supported browsers -->
            <p class="vjs-no-js">To view this video, please enable JavaScript, and consider upgrading to a web browser that supports HTML5 video</p>
        </video>
    </div>

    <div class="dash" style="padding-bottom: 10px;">
        <p class="player-title">Streaming DASH with Storage AZURE CDN</p>
        <video id="dash-video" class="video-js" controls preload="auto" width="640" height="360" data-setup='{}'>
        <!-- DASH source -->
        <source src="https://asset-service-cdn.azureedge.net/artifact-service/Experience Rollout from Boeing Starliner's Perspective-733ba1df/dash-stream/Experience Rollout from Boeing Starliner's Perspective-733ba1df@dash.mpd" type="application/dash+xml">
        <!-- Fallback for non-supported browsers -->
        <p class="vjs-no-js">To view this video, please enable JavaScript, and consider upgrading to a web browser that supports HTML5 video</p>
        </video>
    </div>

    <div class="hls" style="padding-bottom: 10px;">
      <p class="player-title">Streaming with HLS From Storage AZURE CDN</p>
      <video id="hls-video" class="video-js" controls preload="auto" width="640" height="360" data-setup='{}'>
        <source src="https://asset-service-cdn.azureedge.net/artifact-service/Experience Rollout from Boeing Starliner's Perspective-733ba1df/hls-stream/Experience Rollout from Boeing Starliner's Perspective-733ba1df@master.m3u8" type="application/x-mpegURL">-->
        <!-- Fallback for non-supported browsers -->
        <p class="vjs-no-js">To view this video, please enable JavaScript, and consider upgrading to a web browser that supports HTML5 video</p>
      </video>
    </div>

    
  </div>