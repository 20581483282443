import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SharedDataService {

  constructor() { }
  private uploading = new BehaviorSubject<any>(null);
  private progress = new BehaviorSubject<any>(null);
  private progressText = new BehaviorSubject<any>(null);
  private uploadMetaData = new BehaviorSubject<any>(null);
  private videosList = new BehaviorSubject<any>(null);
  private otherMediaList = new BehaviorSubject<any>(null);
  private currentTabIndex = new BehaviorSubject<any>(null);
  private showUploadBanner = new BehaviorSubject<any>(null);
  private scrollToTop =  new BehaviorSubject<any>(null);
  private progressList =  new BehaviorSubject<any>(null);
  private appendProgress = new BehaviorSubject<any>(null);
  private filterValue = new BehaviorSubject<any>(null);
  private newVideo = new BehaviorSubject<any>(null);

  uploading$ = this.uploading.asObservable();
  progress$ = this.progress.asObservable();
  progressText$ = this.progressText.asObservable();
  uploadMetaData$ = this.uploadMetaData.asObservable();
  videosList$ = this.videosList.asObservable();
  otherMediaList$ = this.otherMediaList.asObservable();
  currentTabIndex$ = this.currentTabIndex.asObservable();
  showUploadBanner$ = this.showUploadBanner.asObservable();
  scrollToTop$ = this.scrollToTop.asObservable();
  progressList$ = this.progressList.asObservable();
  appendProgress$ = this.appendProgress.asObservable();
  filterValue$ = this.filterValue.asObservable();
  newVideo$ = this.newVideo.asObservable();

  updateAppendProgress(append:boolean):void{
    this.appendProgress.next(append);
  }

  updateProgressList(progressList:any){
    this.progressList.next(progressList);
  }
  updateScrollToTop(scrollToTop:boolean):void{
    this.scrollToTop.next(scrollToTop);
  }

  updateShowUploadBanner(showUploadBanner:boolean):void{
    this.showUploadBanner.next(showUploadBanner);
  }

  updateUploadingState(uploading: boolean): void {
    this.uploading.next(uploading);
  }

  updateUploadingProgress(progress: any): void {
    this.progress.next(progress);
  }

  updateProgressText(progressText: string): void {
    this.progressText.next(progressText);
  }

  updateUploadMetaData(uploadMetaData:any):void{
    this.uploadMetaData.next(uploadMetaData);
  }

  updateVideosList(videoList:any):void{
    this.videosList.next(videoList);
  }

  updateOtherMediaList(otherMediaList:any):void{
    this.otherMediaList.next(otherMediaList);
  }

  updateTabIndex(index:any):void{
    this.currentTabIndex.next(index);
  }
  updateFilterValue(filter:any):void{
    this.filterValue.next(filter)
  }

  updateNewVideo(video:any):void{
    this.newVideo.next(video)
  }
}