import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BtlSharedLibraryService } from 'btl-shared-library';
import { formatDate } from 'src/app/utils/video-utils';
import { SharedDataService } from '../../services/shared-data.service';
import { ProgressItem } from '../../models/access.model';

export interface DialogData {
  video: any;
}

@Component({
  selector: 'app-edit-video',
  templateUrl: './edit-video.component.html',
  styleUrls: ['./edit-video.component.scss']
})
export class EditVideoFormComponent implements OnInit {

  video: any;
  videoMetaData: any;
  metadataOrder: string[] = [];
  isUploadVideo: boolean = false;
  showBanner: boolean = false;
  selectedFile: File | null = null;
  maxBlockSize: number = 5 * 1024 * 1024;
  numberOfBlocks: number = 1;
  totalBytesRemaining: number = 0;
  disableUploadBtn: boolean = false;
  fileName: any = "";
  newFileName: string = '';
  progress: any;
  progressItems: ProgressItem[] = [];
  videos: any[] = [];
  fileUploadForm?: FormGroup;
  VIDEO_TYPES = ['flv', 'mxf', 'gxf', 'ps', '3gp', '3gpp', 'mpg', 'wmv', 'asf', 'avi', 'mp4', 'm4a', 'm4v', 'isma', 'ismv', 'dvr-ms', 'mkv', 'wav', 'mov'];
  isTitleEditable: boolean = true;
  isFileNameEditable: boolean = true;
  isDescriptionEditable: boolean = true;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialogRef: MatDialogRef<EditVideoFormComponent>,
    private formBuilder: FormBuilder,
    private btlSharedLibraryService: BtlSharedLibraryService,
    private sharedDataService: SharedDataService
  ) { }

  ngOnInit(): void {
    console.log("inside ngoninit");
    this.video = this.data.video;
    this.setVideoMetaData(this.video);
    console.log("fileUploadForm:", this.fileUploadForm);
    this.sharedDataService.progress$.subscribe(progress => {
      this.progress = progress
      console.log("PROGRESS DATA IN FILE:", this.progress);
    });

    this.sharedDataService.progressList$.subscribe(progressItems => {
      this.progressItems = progressItems;
    })
    if (this.progressItems == null) {
      this.progressItems = [];
    }

    this.sharedDataService.videosList$.subscribe(videos => {
      if (videos != null) {
        this.videos = videos;
      }
    })
  }

  cancelUpload() {
    console.log("close ref")
    this.dialogRef.close();
  }

  initUploadFormGroup() {
    this.fileUploadForm = this.formBuilder.group({
      file: [null, [Validators.required]]
    });
  }

  setVideoMetaData(video: any) {
    console.log("inside setVideoMetaData");
    this.metadataOrder = ['Title', 'Description', 'FileName', 'DocumentVersion'];
    console.log(video);
    this.videoMetaData = {
      Title: video.metadata.title,
      Description: video.metadata.description,
      FileName: video.cmsObjectInfo.docName,
      DocumentVersion: video.cmsObjectInfo.documentVer
    };
    console.log(this.videoMetaData);
  }

  handleFileSelect(event: any): void {
    const files = event.target.files;
    this.selectedFile = files[0];

    if (this.selectedFile != null) {
      this.totalBytesRemaining = this.selectedFile.size;
      this.fileName = this.selectedFile.name;
      this.maxBlockSize = this.calculateMaxBlockSize(this.selectedFile.size);

      this.validateFileExtention(event);

      this.fileUploadForm = this.formBuilder.group({
        file: [this.selectedFile ? this.selectedFile.name : null, [Validators.required]]
      });

      // Update the newFileName property with the new video name
      this.newFileName = this.selectedFile.name;
    }
  }

  private calculateMaxBlockSize(fileSizeInBytes: number): number {
    const maxBlockSizeLimit = 5000 * 1024 * 1024; // 5,000 MiB in bytes // Supported by Azure Storage
    let adjustedBlockSize = 5 * 1024 * 1024;
    if (fileSizeInBytes < 1073741824) {
      // Less than 1 GB
      // Condition for files less than 1 GB
      adjustedBlockSize = 5 * 1024 * 1024; // 5MiB
    } else if (fileSizeInBytes < 2147483648) { //1312264066
      // 2 GB
      // Condition for files between 1 GB and 2 GB
      adjustedBlockSize = 10 * 1024 * 1024; // 10MiB
    } else if (fileSizeInBytes < 5368709120) {
      // 5 GB
      // Condition for files between 2 GB and 5 GB
      adjustedBlockSize = 15 * 1024 * 1024; // 10MiB
    } else if (fileSizeInBytes < 7516192768) {
      // 7 GB
      // Condition for files between 5 GB and 7 GB
      adjustedBlockSize = 20 * 1024 * 1024; // 10MiB
    } else if (fileSizeInBytes < 9764456704) {
      // 9 GB
      // Condition for files between 7 GB and 9 GB
      adjustedBlockSize = 30 * 1024 * 1024; // 10MiB
    } else if (fileSizeInBytes < 11906150656) {
      // 11 GB
      // Condition for files between 9 GB and 11 GB
      adjustedBlockSize = 40 * 1024 * 1024; // 10MiB
    } else {
      // Condition for files larger than 11 GB
      adjustedBlockSize = 50 * 1024 * 1024; // 10MiB
    }
    adjustedBlockSize = 5 * 1024 * 1024;
    return adjustedBlockSize;
  }

  validateFileExtention(event: any) {
    this.isUploadVideo = this.VIDEO_TYPES.includes(this.fileName.split('.').pop());
    if (!this.isUploadVideo) {
      console.log("Files:pre:", event.target.files)
      event.target.value = null;
      this.selectedFile = null;
      console.log("Files:", event.target.files)
      this.initUploadFormGroup();
      this.showBanner = true;
      this.btlSharedLibraryService.updateStatusMessage(true, "Invalid file extension. Allowed extensions are mp4, wav, mov, avi, flv, mpeg, mkv", false);
    }

    setTimeout(() => {
      this.showBanner = false;
    }, 4000)
  }

  isEditable(key: string): boolean {
    console.log(key);
    return (this as any)[`is${key}Editable`] && key !== 'DocumentVersion';
  }

  toggleEditable(key: string): void {
    if (key !== 'DocumentVersion') {
      (this as any)[`is${key}Editable`] = !(this as any)[`is${key}Editable`];
    }
  }
}