import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { VideoPlayerDialogComponent } from 'src/app/components/video-player-dialog/video-player-dialog.component';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { VideoService } from 'src/app/services/video-service';
import { SpinnerOverlayService } from 'src/app/services/spinner-overlay-service';
import { Video } from 'src/app/models/videos.model';
import { BtlSharedLibraryService } from 'btl-shared-library';
import { ACCEPTABLE_TYPES } from 'src/app/constants/constants';
import { INgxLoadingConfig, ngxLoadingAnimationTypes } from 'ngx-loading';
import { Router } from '@angular/router';
import { FileUploadDialogComponent } from '../file-upload-dialog/file-upload-dialog.component';
import { DownloadProgress } from 'src/app/models/access.model';
import { HttpEventType } from '@angular/common/http';
import { saveAs as importedSaveAs } from 'file-saver';
import { MatMenuTrigger } from '@angular/material/menu';
import { formatDate, removeProgressItem, sortVideosByCreatedDate, updateProgressItem } from 'src/app/utils/video-utils';
import { ProgressItem } from 'src/app/models/access.model';
import { symlinkSync } from 'fs';
import { EditVideoFormComponent } from '../edit-video/edit-video.component';
import { DownloadMetadata } from '../../models/download-meta-data.model';
@Component({
  selector: 'app-video-list-component',
  templateUrl: './video-list-component.component.html',
  styleUrls: ['./video-list-component.component.scss']
})
export class VideoListComponentComponent implements OnInit {
  videos: any[] = [];
  ascendingOrder: boolean = true;
  latestCreatedVideos: boolean = true;

  constructor(public dialog: MatDialog,
    private router: Router,
    private sharedDataService: SharedDataService,
    private videoService: VideoService,
    private spinnerOverlayService: SpinnerOverlayService,
    private btlSharedLibraryService: BtlSharedLibraryService,
  ) { }

  disableUploadBtn: boolean = false;
  @ViewChild('menuTrigger')
  menuTrigger!: MatMenuTrigger

  @ViewChild('uploadAsset')
  uploadAsset!: ElementRef;
  uploading = false;
  progressText = "Processing ...";
  progress = 0;
  uploadMetaData: any = {};
  show: boolean = true;
  zIndex: number = 1000;
  configLoading: INgxLoadingConfig = {};
  loadMore: boolean = true;
  loadMoreMode: boolean = true;
  loadForUpload: boolean = true;
  backdropBackgroundColour: string = '#F0F7FB';

  isFixed: boolean = false;
  showUploadBanner: boolean = true;

  loaded!: string;
  total?: string;
  percentDone!: number;
  downloadProgress: any;
  downloadId: string | undefined;
  progressItems: ProgressItem[] = []
  downloading: boolean = false;
  downloadMetadata: DownloadMetadata | undefined;
  videoDownloadStates: Map<string, boolean> = new Map<string, boolean>();
  downloadMetadataMap: Map<string, DownloadMetadata> = new Map<string, DownloadMetadata>(); // Map to store download metadata

  // downloadMetadata: DownloadMetadata = {
  //   fileName: '',
  //   progress: 0,
  //   progressText: '',
  //   totalSize: '',
  //   remainingSize: ''
  // };

  ngOnInit(): void {
    this.sharedDataService.videosList$.subscribe(videos => {
      if (videos != null) {
        this.videos = videos;
      } else {
        this.getAllVideos();
      }
    })

    this.sharedDataService.progress$.subscribe(progress => {
      this.progress = progress
    })

    this.sharedDataService.uploading$.subscribe(uploading => {
      this.uploading = uploading
    })

    this.sharedDataService.uploadMetaData$.subscribe(uploadMetaData => {
      this.uploadMetaData = uploadMetaData;
    })

    this.sharedDataService.progressText$.subscribe(progressText => {
      this.progressText = progressText;
    })

    this.sharedDataService.showUploadBanner$.subscribe(showUploadBanner => {
      this.showUploadBanner = showUploadBanner;
    })

    this.configLoading = {
      animationType: ngxLoadingAnimationTypes.circle,
      backdropBackgroundColour: this.loadMoreMode ? 'rgba(0,0,0,0)' : (this.backdropBackgroundColour ? this.backdropBackgroundColour : 'rgba(0,0,0,0.8)'),
      primaryColour: this.loadMoreMode ? '#475F74' : '#384C5E',
      secondaryColour: this.loadMoreMode ? '#ccc' : '#0088CB'
    };

    this.sharedDataService.progressList$.subscribe(progressItems => {
      this.progressItems = progressItems;
    })

    if (this.progressItems == null) {
      this.progressItems = [];
    }

  }

  getAllVideos(): void {
    this.spinnerOverlayService.show()
    this.videoService.getAllVideosFromCms('tsvideo').subscribe({
      next: (response) => {
        console.log(response.data)
        this.videos = response.data.data;
        this.toggleDateOrder();
        console.log("Videos:", this.videos)
        this.sharedDataService.updateVideosList(this.videos);
        this.spinnerOverlayService.hide()
      },
      error: (error) => {
        console.error('Error fetching videos:', error);
        this.spinnerOverlayService.hide()
      }
    });
  }

  openVideoDialog(video: any): void {
    console.log("Play Video dialog:", video.metadata);
    this.spinnerOverlayService.show();

    this.videoService.getCmsSassStreamUrl(video.contentKey).subscribe(
      (response) => {
        // decode response
        let encodedData = response.data;
        const decodedString = atob(encodedData);
        const decodedJsonResponse =  JSON.parse(decodedString);
        response.data = decodedJsonResponse;
        video.streamingUrl = response.data.data.documentURL;
        this.spinnerOverlayService.hide();
        console.log("SASS STREAMING URL::", video.streamingUrl.length);

        // Open dialog only after getting the streaming URL
        const dialogRef = this.dialog.open(VideoPlayerDialogComponent, {
          width: '70%',
          data: { video }
        });
      },
      (e) => {
        console.log("Error Stream URL:", e);
        this.spinnerOverlayService.hide();
        const errorMessage = e.error && e.error.message ? e.error.message : "An error occurred while fetching stream url.";
        // Open dialog only after getting the streaming URL
        const dialogRef = this.dialog.open(VideoPlayerDialogComponent, {
          width: '70%',
          data: { video }
        });
      }
    );
  }

  openEditDialog(video: any): void {
    this.dialog.open(EditVideoFormComponent, {
      height: '90%',
      width: '75%',
      data: { video }
    });
  }

  openDetails(video: Video, action: string): void {
    // Implement logic to open details based on the selected video
    console.log('Opening details for:', video, action);
    switch (action) {
      case 'download':
        this.downloadDocument(video);
        break;
      case 'edit':
        // TODO;
        break;
      default:
        break;
    }
  }

  downloadDocument(video: any) {
    console.log("Download document:",video,this.progressItems)
    this.closeMenu();
    this.loaded = "";
    this.total = "";
    this.percentDone = 0;
    window.scroll(0, 0);

    // Set download state for this video to true
    this.videoDownloadStates.set(video.contentKey, true);

    // Initialize progress for this download
    let videoMetadata = {
      fileName: video.cmsObjectInfo.docName,
      contentKey: video.contentKey
    }

    this.downloadMetadataMap.set(video.cmsObjectInfo.docObjectId, {
      fileName: video.cmsObjectInfo.docName,
      progress: 0,
      progressText: 'Downloading...',
      totalSize: '',
      remainingSize: ''
    });

    // this.downloadMetadata = {
    //   fileName: video.cmsObjectInfo.docName,
    //   progress: 0,
    //   progressText: 'Downloading...',
    //   totalSize: '',
    //   remainingSize: ''
    // };
    this.downloading = true;
    // Function to update download progress
    const updateDownloadProgress = (progress: number, totalSize: string, remainingSize: string) => {
      const downloadMetadata = this.downloadMetadataMap.get(video.cmsObjectInfo.docObjectId);
      if (downloadMetadata) {
        downloadMetadata.progress = progress;
        downloadMetadata.totalSize = totalSize;
        downloadMetadata.remainingSize = remainingSize;
      }
    };
    // Initialize progress item
    // let progressItem = new ProgressItem();
    // progressItem.uploading = true;
    // progressItem.progress = 1;
    // progressItem.uploadMetaData = videoMetadata;
    // progressItem.progressText = "Downloading...";
    // this.progressItems.push(progressItem);
    // this.sharedDataService.updateProgressList(this.progressItems);

    this.sharedDataService.updateUploadMetaData(videoMetadata);
    this.videoService.downloadArtifact('TSVIDEO', video.contentKey).subscribe({
      next: event => {
        if (event.type === HttpEventType.DownloadProgress) {
          if (event.total && event.total > 0) {
            this.percentDone = Math.round(100 * event.loaded / event.total);
            this.total = this.formatBytes(event.total);
            let currentDownloadingState = event.loaded <= event.total;
            const totalSize = event.total ? this.formatBytes(event.total) : 'Unknown';
            const remainingSize = event.total && event.loaded ? this.formatBytes(event.total - event.loaded) : 'Unknown';
            const progress = Math.round(100 * event.loaded / event.total);
            updateDownloadProgress(progress, totalSize, remainingSize);

          }
          this.loaded = this.formatBytes(event.loaded);
        } else if (event.type === HttpEventType.Response) {
          this.videoDownloadStates.set(video.contentKey, false);
          this.downloading = false;
          this.sharedDataService.updateShowUploadBanner(true);
          this.btlSharedLibraryService.updateStatusMessage(true, `${video.cmsObjectInfo.docName} downloaded successfully`, true);
          importedSaveAs(event.body, video.cmsObjectInfo.docName);
          //this.progressItems = removeProgressItem(this.progressItems, video.contentKey);

          // Remove download metadata for this video when download completes
          this.downloadMetadataMap.delete(video.cmsObjectInfo.docObjectId);
        }
      },
      error: error => {
        console.log('Download Artifact Error', error);
        this.videoDownloadStates.set(video.contentKey, false);
        this.downloading = false;
        const downloadMetadata = this.downloadMetadataMap.get(video.cmsObjectInfo.docObjectId);
        if (downloadMetadata) {
          downloadMetadata.progressText = 'Download failed';
        }
        //this.progressItems = removeProgressItem(this.progressItems, video.contentKey);
        this.sharedDataService.updateShowUploadBanner(true);
        this.btlSharedLibraryService.updateStatusMessage(true, error.message, false);
        // Remove download metadata for this video when download fails
        this.downloadMetadataMap.delete(video.cmsObjectInfo.docObjectId);
      }
    });
  }

  closeMenu() {
    if (this.menuTrigger && this.menuTrigger.menuOpen) {
      this.menuTrigger.closeMenu();
    }
  }

  formatBytes(bytes: any, decimals = 2) {
    if (!+bytes) return '0 Byte';
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  openFileUploadDialog() {
    console.log("Open File Upload Dialog");
    console.log("window.innerHeight : ", window.innerHeight);
    let heightRatio = 0;
    let widthRatio = 0;
    if (window.innerHeight > 900) {
      heightRatio = 7;
      widthRatio = 2.5;
    } else {
      heightRatio = 10;
      widthRatio = 3.5;
    }
    const windowHeight = (window.innerHeight * heightRatio) / 100;
    const windowWidth = (window.innerWidth * widthRatio) / 100;
    const dialogRef = this.dialog.open(FileUploadDialogComponent, {
      width: `${windowWidth}%`,
      height: `${windowHeight}%`,
      data: {}
    });
  }

  toggleSortOrder() {
    this.ascendingOrder = !this.ascendingOrder;
    this.sortVideos();
  }

  sortVideos() {
    this.videos.sort((a, b) => {
      const nameA = a.metadata.title.toUpperCase();
      const nameB = b.metadata.title.toUpperCase();

      return this.ascendingOrder ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
    });
  }

  toggleDateOrder() {
    this.latestCreatedVideos = !this.latestCreatedVideos;
    this.videos = sortVideosByCreatedDate(this.videos, this.latestCreatedVideos)
    this.sharedDataService.updateVideosList(this.videos);
  }

  formatDate(date: string) {
    return formatDate(date)
  }
}