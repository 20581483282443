import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTreeModule } from '@angular/material/tree';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatChipsModule } from '@angular/material/chips';
import { StylePaginatorDirective } from './directive/style-paginator.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxLoadingModule } from 'ngx-loading';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { HttpClientModule } from '@angular/common/http';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SpinnerOverlayComponent } from './components/spinner-overlay/spinner-overlay.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HeaderComponentComponent } from './components/header-component/header-component.component';
import { VideoListComponentComponent } from './components/video-list-component/video-list-component.component';
import { UploadProgressComponent } from './components/upload-progress/upload-progress.component';
import { VideoPlayerDialogComponent } from './components/video-player-dialog/video-player-dialog.component';
import { BtlSharedLibraryModule } from 'btl-shared-library';
import { PocVideoPlayerComponent } from './components/poc-video-player/poc-video-player.component';
import { FileUploadDialogComponent } from './components/file-upload-dialog/file-upload-dialog.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { EditVideoFormComponent } from './components/edit-video/edit-video.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';

import { DownloadVideoComponent } from './components/download-video/download-video.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import { MainVideoListComponent } from './components/main-video-list/main-video-list.component';
import { MetadataComponent } from './components/metadata/metadata.component';
import { FormatControlNamePipe } from './pipe/format-control-name.pipe';
import { UploadDialogComponent } from './components/upload-dialog/upload-dialog.component';
import { StreamProcessedVideosDemoComponent } from './components/stream-processed-videos-demo/stream-processed-videos-demo.component';
import { PocDynamicVideoPlayerComponent } from './components/poc-dynamic-video-player/poc-dynamic-video-player.component';

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    SpinnerOverlayComponent,
    HeaderComponentComponent,
    VideoListComponentComponent,
    UploadProgressComponent,
    VideoPlayerDialogComponent,
    PocVideoPlayerComponent,
    FileUploadDialogComponent,
    UnauthorizedComponent,
    EditVideoFormComponent,
    LandingPageComponent,
    DownloadVideoComponent,
    MainVideoListComponent,
    StylePaginatorDirective,
    MetadataComponent,
    FormatControlNamePipe,
    UploadDialogComponent,
    StreamProcessedVideosDemoComponent,
    PocDynamicVideoPlayerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    DragDropModule,
    MatMenuModule,
    MatTreeModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    MatChipsModule,
    MatCardModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatInputModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTabsModule,
    MatTooltipModule,
    BtlSharedLibraryModule,
    NgxLoadingModule.forRoot({}),
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'right',
        },
        vertical: {
          position: 'top',
          distance: 72
        }
      },
      theme: 'material',
      behaviour: {
        onClick: 'hide',
        onMouseover: 'pauseAutoHide',
        showDismissButton: true,
        stacking: 4
      },
      animations: {
        enabled: true,
        show: {
          preset: 'slide',
          speed: 300,
          easing: 'ease'
        },
        hide: {
          preset: 'fade',
          speed: 300,
          easing: 'ease',
          offset: 50
        },
        shift: {
          speed: 300,
          easing: 'ease'
        },
        overlap: 150
      }
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
