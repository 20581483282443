import { Component, OnInit ,Inject,AfterViewInit, ViewChild, ElementRef} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import videojs from 'video.js';
import { environment } from 'src/environments/environment';
import { VideoService } from 'src/app/services/video-service';
import { BtlSharedLibraryService } from 'btl-shared-library';
import { SpinnerOverlayService } from 'src/app/services/spinner-overlay-service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { formatDate } from 'src/app/utils/video-utils';
export interface DialogData {
  video: any;
}

@Component({
  selector: 'app-video-player-dialog',
  templateUrl: './video-player-dialog.component.html',
  styleUrls: ['./video-player-dialog.component.scss']
})
export class VideoPlayerDialogComponent implements OnInit,AfterViewInit {

  video:any;
  videoMetaData:any
  private player: any;
  private hlsPlayer: any;
  private dashPlayer: any;
  metadataOrder:string[]=[];
  showPlayerStatus:boolean = false;

  public videoJsConfigObj = {
    preload: "metadata",
    controls: true,
    muted:true,
    autoplay: false,
    overrideNative: true,
    html5: {
        nativeVideoTracks: false,
        nativeAudioTracks: false,
        nativeTextTracks: false,
        hls: {
            withCredentials: false,
            overrideNative: true,
            debug: true
        }
    },
    controlBar: {
      skipButtons: {
        forward: 5,
        backward:5
      }
    }
};
@ViewChild('header-id')
element!: ElementRef;
marginValue:number=0;

  constructor(
    private dialogRef: MatDialogRef<VideoPlayerDialogComponent>,
    private videoService:VideoService,
    private btlSharedLibraryService:BtlSharedLibraryService,
    private spinnerOverlayService:SpinnerOverlayService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private sharedDataService:SharedDataService,
  ) { }

  ngOnInit(): void {
    this.video=this.data.video;
    this.setVideoMetaData(this.video);
    console.log("Video on dialog:",this.video.metadata)
    if(this.video.streamingUrl=== undefined){
      this.sharedDataService.updateShowUploadBanner(false);
      this.showPlayerStatus=true;
      this.btlSharedLibraryService.updateStatusMessage(true, "An error occurred while fetching video streaming url.", false);
    }
    // this.video.streamingUrl = `${environment.backendBaseUrl}/stream/${this.video.videoId}@master.m3u8`;
    setTimeout(() => {
      this.initializeVideo();
    }, 5);
  }

  setVideoMetaData(video: any) {
    this.metadataOrder = ['Title', 'ArtifactId', 'FileName', 'Description', 'CreatedOn'];
    this.videoMetaData = {
      Title: video.metadata.title,
      ArtifactId: video.contentKey,
      FileName: video.cmsObjectInfo.docName,
      Description: video.metadata.description,
      CreatedOn: formatDate(video.cmsObjectInfo.createdDate)
    };
  }
  
  private initializeVideo(): void {
    this.player = videojs('video-player',this.videoJsConfigObj);
  }

  ngOnDestroy(): void {
    if (this.player) {
      this.player.dispose();
      //this.hlsPlayer.dispose();
      //this.dashPlayer.dispose();
    }
  }
  cancelPlayer() {
    console.log("close ref")
    this.dialogRef.close();
  }
  ngAfterViewInit(): void {
    console.log('ngAfterViewInit hook has been called');
    if(window.innerHeight>900){
      this.marginValue=93.572;
    } else{
      this.marginValue=28.5972;
    }
  }
}
