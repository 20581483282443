import { AfterViewInit, Component, OnInit,ElementRef ,Renderer2} from '@angular/core';
import videojs from 'video.js';
import  'videojs-seek-buttons';
@Component({
  selector: 'app-poc-video-player',
  templateUrl: './poc-video-player.component.html',
  styleUrls: ['./poc-video-player.component.scss']
})
export class PocVideoPlayerComponent implements OnInit ,AfterViewInit{


  constructor(private elementRef: ElementRef,private renderer: Renderer2) { }

  private player: any;
  private hlsPlayer: any;
  private dashPlayer: any;
  private azureCdnDashVideo:any;
  private azureCdnVideo:any;
  private cmsStorageSassPlayer:any;

  public videoJsConfigObj = {
    preload: "metadata",
    muted:true,
    controls: true,
    autoplay: true,
    overrideNative: true,
    techOrder: ["html5", "flash"],
    html5: {
        nativeVideoTracks: false,
        nativeAudioTracks: false,
        nativeTextTracks: false,
        hls: {
            withCredentials: false,
            overrideNative: true,
            debug: true
        }
    },
    controlBar: {
      skipButtons: {
        forward: 5,
        backward:5
      }
    },
    qualitySelector: {
      default: 'high', 
      dynamicLabel: true 
    },
    playbackRates: [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
};

  ngOnInit(): void {
    setTimeout(() => {
      this.initializeVideo();
    }, 1);
  }

  ngAfterViewInit() {
    this.dashPlayer = videojs(this.elementRef.nativeElement.querySelector('#dash-video'), {muted:true,autoplay:true}, function() {});
    
    // Add seek buttons plugin
    this.dashPlayer.seekButtons({
      forward: 30,
      back: 10
    });

    this.cmsStorageSassPlayer = videojs(this.elementRef.nativeElement.querySelector('#cms-storage-sass-player'), {muted:true,autoplay:true}, function() {});
    
    // Add seek buttons plugin
    this.cmsStorageSassPlayer.seekButtons({
      forward: 30,
      back: 10
    });

      
  } 

  private initializeVideo(): void {
    this.dashPlayer = videojs('dash-video', this.videoJsConfigObj);
    this.hlsPlayer = videojs('hls-video',this.videoJsConfigObj);
    //this.azureCdnDashVideo = videojs('azure-cdn-dash-video',this.videoJsConfigObj);
    this.azureCdnVideo = videojs('azure-cdn-video',this.videoJsConfigObj);
    this.cmsStorageSassPlayer = videojs('cms-storage-sass-player',this.videoJsConfigObj);
  }

  ngOnDestroy(): void {
    if (this.player) {
      this.player.dispose();
      this.hlsPlayer.dispose();
      this.dashPlayer.dispose();
      this.azureCdnVideo.dispose();
      this.cmsStorageSassPlayer.dispose();
    }
  }
}
