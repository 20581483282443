import { Component, OnInit ,Inject,AfterViewInit, ViewChild, ElementRef} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import videojs from 'video.js';
import { environment } from 'src/environments/environment';
import { VideoService } from 'src/app/services/video-service';
import { BtlSharedLibraryService } from 'btl-shared-library';
import { SpinnerOverlayService } from 'src/app/services/spinner-overlay-service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { formatDate } from 'src/app/utils/video-utils';
import { VideoPlayerDialogComponent } from '../video-player-dialog/video-player-dialog.component';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import 'videojs-http-source-selector';
import "video.js/dist/video-js.css";
import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import * as MP4Box from 'mp4box';

export interface DialogData {
  video: any;
}
interface ArrayBufferWithFileStart extends ArrayBuffer {
  fileStart?: number;
}

@Component({
  selector: 'app-poc-dynamic-video-player',
  templateUrl: './poc-dynamic-video-player.component.html',
  styleUrls: ['./poc-dynamic-video-player.component.scss']
})
export class PocDynamicVideoPlayerComponent implements OnInit {
  video:any;
  videoMetaData:any
  renditions:any[] = []
  private player: any;
  private hlsPlayer: any;
  private dashPlayer: any;
  metadataOrder:string[]=[];
  showPlayerStatus:boolean = false;
  imageUrl: SafeUrl | null = null;
  defaultStreamingUrl: SafeUrl | null = null;
  mimeCodec = 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"';
  
  public videoJsConfigObj = {
    preload: "metadata",
    controls: true,
    muted:true,
    autoplay: false,
    overrideNative: true,
    enableSmoothSeeking: true,
    html5: {
        nativeVideoTracks: false,
        nativeAudioTracks: false,
        nativeTextTracks: false,
        hls: {
            withCredentials: false,
            overrideNative: true,
            debug: true
        }
    },
    controlBar: {
      skipButtons: {
        forward: 5,
        backward:5
      },
    },
    qualitySelector: {
      default: 'auto', 
      dynamicLabel: true 
    },
    playbackRates: [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
};
@ViewChild('header-id')
element!: ElementRef;
marginValue:number=0;

@ViewChild("videoElement") playerTest!: ElementRef;

constructor(
  private dialogRef: MatDialogRef<VideoPlayerDialogComponent>,
  private videoService:VideoService,
  private btlSharedLibraryService:BtlSharedLibraryService,
  private spinnerOverlayService:SpinnerOverlayService,
  private sanitizer: DomSanitizer,
  @Inject(MAT_DIALOG_DATA) public data: DialogData,
  private sharedDataService:SharedDataService,
  private http: HttpClient
) { }

  ngOnInit(): void {
    this.video=this.data.video;
    this.setVideoMetaData(this.video);
    this.renditions = this.video.renditions;
    console.log("Video on dialog:",this.video.metadata)
    this.setThumbNail();
    this.setDefaultStreamingUrl();
    //this.setUpMediaSource();
    if(this.video.renditions === undefined){
      this.sharedDataService.updateShowUploadBanner(false);
      this.showPlayerStatus=true;
      this.btlSharedLibraryService.updateStatusMessage(true, "An error occurred while fetching video's renditions.", false);
    }
    setTimeout(() => {
      this.initializeVideo();
    }, 5);
  }

  initializeVideoPlayer(): void {
    const videoElement = this.playerTest.nativeElement;
    const mediaSource = new MediaSource();
    videoElement.src = URL.createObjectURL(mediaSource);
  
    let mp4boxFile: any;
    let sourceBuffer: SourceBuffer;
    let isAppending = false;
  
    mediaSource.addEventListener('sourceopen', () => {
      console.log("MediaSource opened");
      mp4boxFile = MP4Box.createFile();
  
      mp4boxFile.onError = (e: any) => console.error('mp4box error:', e);
      mp4boxFile.onReady = (info: any) => {
        console.log('MP4 file ready:', info);
        const mimeCodec = info.mime;
        console.log("MIME CODEC:", mimeCodec);
        try {
          sourceBuffer = mediaSource.addSourceBuffer(mimeCodec);
          console.log("SourceBuffer created:", sourceBuffer);
        } catch (e) {
          console.error('Error creating SourceBuffer:', e);
          return;
        }
  
        sourceBuffer.addEventListener('updateend', () => {
          console.log('SourceBuffer updateend');
          isAppending = false;
          if (mediaSource.readyState === 'open' && !sourceBuffer.updating) {
            checkEndOfStream();
          }
        });
  
        mp4boxFile.setSegmentOptions(info.tracks[0].id, null, { nbSamples: 1 });
        const initSegs = mp4boxFile.initializeSegmentation();
        if (initSegs && initSegs.length > 0) {
          console.log("Appending initialization segment");
          sourceBuffer.appendBuffer(initSegs[0].buffer);
        } else {
          console.error("No initialization segments found");
        }
      };
  
      mp4boxFile.onSegment = (id: number, user: any, buffer: ArrayBuffer) => {
        console.log('Received segment:', id, buffer);
        if (!sourceBuffer.updating && !isAppending) {
          isAppending = true;
          sourceBuffer.appendBuffer(buffer);
          console.log('Segment appended to SourceBuffer');
        } else {
          console.log("SourceBuffer is updating or already appending, cannot append buffer now");
        }
      };
  
      const rendition = this.getRenditionByType("transcodedVideo");
      console.log("get rendition:", rendition);
      this.fetchAndProcessVideo(mp4boxFile, rendition);
    });
  
    mediaSource.addEventListener('error', (e) => {
      console.error('MediaSource error:', e);
    });
  
    mediaSource.addEventListener('sourceclose', () => {
      console.log('MediaSource closed');
    });
  
    function checkEndOfStream() {
      if (mediaSource.readyState === 'open' && sourceBuffer.buffered.length > 0) {
        const endTime = sourceBuffer.buffered.end(sourceBuffer.buffered.length - 1);
        if (endTime >= videoElement.duration) {
          mediaSource.endOfStream();
          console.log('MediaSource endOfStream called');
        }
      }
    }
  }
  
  fetchAndProcessVideo(mp4boxFile: any, rendition: any): void {
    const headers = new HttpHeaders({ 'Ocp-Apim-Subscription-Key': environment.btlVsSubscriptionKey });
    let assetURL = `${environment.backendBaseUrl}/stream/rendition/range?type=${rendition.type}&canonicalPath=${rendition.canonicalPath[0]}`;
    console.log("Download Video from:", assetURL);
    this.http.get(assetURL, { headers, responseType: 'arraybuffer' })
      .subscribe(data => {
        console.log("VIDEO DATA received");
        const arrayBuffer = data as ArrayBufferWithFileStart;
        console.log("arrayBuffer:", arrayBuffer);
        arrayBuffer.fileStart = 0;
        mp4boxFile.appendBuffer(arrayBuffer);
        mp4boxFile.flush();
        console.log("mp4boxFile flushed:", mp4boxFile);
      }, error => {
        console.error("Error fetching video data:", error);
      });
  }
  
  
  
  
  setVideoMetaData(video: any) {
    this.metadataOrder = ['Title', 'ArtifactId', 'FileName', 'Description', 'CreatedOn'];
    this.videoMetaData = {
      Title: video.metadata.title,
      ArtifactId: video.contentKey,
      FileName: video.cmsObjectInfo.docName,
      Description: video.metadata.description,
      CreatedOn: formatDate(video.cmsObjectInfo.createdDate)
    };
  }

  setThumbNail(): void {
     console.log("Video renditions:",this.video.renditions)
     const rendition = this.getRenditionByType("thumbnail");
     console.log("get rendition:",rendition);

     this.videoService.streamRenditionUsingRange(rendition.type, rendition.canonicalPath).subscribe({
      next: (response:Blob) => {
          const objectUrl = URL.createObjectURL(response);
          this.imageUrl = this.sanitizer.bypassSecurityTrustUrl(objectUrl);
      },
      error: (error) => {
          console.error('Error fetching image', error);
      }
    });
  }

  setDefaultStreamingUrl(): void {
    console.log("SetDefaultStreamingUrl Video renditions:",this.video.renditions)
    const rendition = this.getRenditionByType("transcodedVideo");
    console.log("transcodedVideo rendition:",rendition);
    //this.defaultStreamingUrl = `${environment.backendBaseUrl}/stream/rendition/range?type=${rendition.type}&canonicalPath=${rendition.canonicalPath[0]}`
    // setTimeout(() => {
    //   this.initializeVideo();
    // }, 5);
    this.videoService.streamRenditionUsingRange(rendition.type, rendition.canonicalPath[0]).subscribe({
     next: (response:Blob) => {
         const objectUrl = URL.createObjectURL(response);
         this.defaultStreamingUrl = this.sanitizer.bypassSecurityTrustUrl(objectUrl);
         console.log("Default Streaming URL:",this.defaultStreamingUrl)
         this.initializeVideo();
     },
     error: (error) => {
         console.error('Error fetching image', error);
     }
   });
 }

//  async setDefaultStreamingUrl(): Promise<void> {
//     const rendition = this.getRenditionByType("transcodedVideo");
//     try {
//       const videoStream = await this.videoService.streamVideoUsingFetch(rendition.type, rendition.canonicalPath[0]);
//       this.initializeVideoWithStream(videoStream);
//     } catch (error) {
//       console.error('Error fetching video stream', error);
//     }
//   }

  setUpMediaSource(){
    const rendition = this.getRenditionByType("transcodedVideo");
    console.log("transcodedVideo rendition:",rendition);
    if (
      "MediaSource" in window &&
      MediaSource.isTypeSupported(this.mimeCodec)
    ) {
      const mediaSource = new MediaSource();
      (this.playerTest.nativeElement as HTMLVideoElement).src = URL.createObjectURL(
        mediaSource
      );
      this.defaultStreamingUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(mediaSource));
      mediaSource.addEventListener("sourceopen", () =>
        this.sourceOpen(mediaSource,rendition)
      );
      setTimeout(() => {
        this.initializeVideo();
      }, 5);
    } else {
      console.error("Unsupported MIME type or codec: ", this.mimeCodec);
    }
  }

  sourceOpen(mediaSource:any,rendition:any) {
    //const sourceBuffer = mediaSource.addSourceBuffer(this.mimeCodec);
    const headers = new HttpHeaders({ 'Ocp-Apim-Subscription-Key': environment.btlVsSubscriptionKey });
    let assetURL= `${environment.backendBaseUrl}/stream/rendition/range?type=${rendition.type}&canonicalPath=${rendition.canonicalPath[0]}`
    let sourceBuffer:any;
    if (mediaSource.readyState === 'open') {
      // Initialize the SourceBuffer
        sourceBuffer = mediaSource.addSourceBuffer('video/webm; codecs="vp8, vorbis"');
    }
    return this.http
      .get(assetURL, { headers, responseType: "blob" })
      .subscribe(blob => {
        sourceBuffer.addEventListener("updateend", () => {
          mediaSource.endOfStream();
          this.playerTest.nativeElement.play();
        });
        blob.arrayBuffer().then(x => sourceBuffer.appendBuffer(x));
      });
  }

  async initializeVideoWithStream(videoStream: ReadableStream<Uint8Array>): Promise<void> {
    const videoElement = document.getElementById('video-player') as HTMLVideoElement;

    if (videoElement && videoStream) {
      const mediaSource = new MediaSource();
      videoElement.src = URL.createObjectURL(mediaSource);
      this.player = videojs('video-player', this.videoJsConfigObj);
      console.log("player:",this.player)
      mediaSource.addEventListener('sourceopen', () => {
        const mimeType = 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"'; // Example MIME type with codecs
        if (MediaSource.isTypeSupported(mimeType)) {
          const sourceBuffer = mediaSource.addSourceBuffer(mimeType);

          sourceBuffer.addEventListener('updateend', () => {
            if (mediaSource.readyState === 'open' && !sourceBuffer.updating && reader) {
              readStream();
            }
          });

          sourceBuffer.addEventListener('error', (e) => {
            console.error('SourceBuffer error:', e);
          });

          sourceBuffer.addEventListener('abort', () => {
            console.warn('SourceBuffer aborted');
          });

          const reader = videoStream.getReader();
          const readStream = async () => {
            try {
              const { done, value } = await reader.read();
              if (done) {
                mediaSource.endOfStream();
                return;
              }
              sourceBuffer.appendBuffer(value);
            } catch (error) {
              console.error('Stream reading error:', error);
            }
          };

          readStream();
        } else {
          console.error('MIME type or codec not supported:', mimeType);
        }
      });
    } else {
      console.error('Video element or video stream not found');
    }
  }


  private getRenditionByType(type:string):any{
    return this.renditions.find(rendition => rendition.type === type);
  }

  private initializeVideo(): void {
    this.player = videojs('video-player',this.videoJsConfigObj);
    let qualityLevels = this.player.qualityLevels();
    console.log("Player::",this.player)
  }

  ngOnDestroy(): void {
    if (this.player) {
      this.player.dispose();
      //this.hlsPlayer.dispose();
      //this.dashPlayer.dispose();
    }
  }
  cancelPlayer() {
    console.log("close ref")
    this.dialogRef.close();
  }
  ngAfterViewInit(): void {
    console.log('ngAfterViewInit hook has been called');
    if(window.innerHeight>900){
      this.marginValue=93.572;
    } else{
      this.marginValue=28.5972;
    }


    // set up media source
    const rendition = this.getRenditionByType("transcodedVideo");
    console.log("transcodedVideo rendition:",rendition);
    //this.initializeVideoPlayer();

    // if (
    //   "MediaSource" in window &&
    //   MediaSource.isTypeSupported(this.mimeCodec)
    // ) {
    //   const mediaSource = new MediaSource();
    //   (this.playerTest.nativeElement as HTMLVideoElement).src = URL.createObjectURL(
    //     mediaSource
    //   );
    //   //this.defaultStreamingUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(mediaSource));
    //   mediaSource.addEventListener("sourceopen", () =>
    //     this.sourceOpen(mediaSource,rendition)
    //   );
    //   setTimeout(() => {
    //     this.initializeVideo();
    //   }, 5);
    // } else {
    //   console.error("Unsupported MIME type or codec: ", this.mimeCodec);
    // }
  }
}


