import { ProgressItem } from "../models/access.model";
import { Video } from "../models/videos.model";
import { SharedDataService } from "../services/shared-data.service";
export function formatVideos(data:any){
    return data
    .filter((d: any) => d.fileName !== null && d.createdOn !== null)
    .map((video:Video) => {
      const date = new Date(video.videoIssuedDate);
      //const formattedDate = date.toLocaleDateString("en-US");
      const formattedDate = new Intl.DateTimeFormat('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZone: 'UTC'
      }).format(date);
      // Extracting file format by trimming the extension
      const fileFormat = video.fileName.split('.').pop();

      return { ...video, videoIssuedDate: formattedDate, fileFormat };
    })
    .sort((a:any, b:any) => {
      const dateA = new Date(a.videoIssuedDate) as any;
      const dateB = new Date(b.videoIssuedDate) as any;
      return dateA.toLocaleString()-dateB.toLocaleString();
    });
  }


  export function formatDate(dateStr:string){
    let date = new Date(dateStr);
    let timeString = date.toLocaleTimeString();
    return `${date.getMonth()+1}-${date.getDate()}-${date.getFullYear()}   ${timeString}`
  }

  export function removeProgressItem(progressItems:any,contentKey:any){
    return progressItems.filter((item:ProgressItem)=> item.uploadMetaData.contentKey!=contentKey)
  }
  export function updateProgressItem(progressItems:any,contentKey: any, currentUploadingState: boolean, percentDone: number,progressText:string) {
    console.log("Updating progress items:",contentKey,currentUploadingState,percentDone,progressText);
    console.log("Current Progress items:",progressItems)
    progressItems = progressItems.map((progressItem:ProgressItem) => {
      if (progressItem.uploadMetaData.contentKey === contentKey) {
        return { ...progressItem, uploading: currentUploadingState, progress: percentDone ,progressText:progressText};
      }
      return progressItem;
    });
    return progressItems;
  }

  export function sortVideosByCreatedDate(videos: any[],latestCreatedVideos:any): any[] {
    return videos.map(video => ({
      ...video,
      downloading: false,
      paused: false,
      progress:0,
      downloaded:0,
      total:0
    })).sort((a, b) => {
      const dateA = new Date(a.cmsObjectInfo.createdDate).getTime();
      const dateB = new Date(b.cmsObjectInfo.createdDate).getTime();
      return latestCreatedVideos ? dateA - dateB : dateB - dateA;
    });
  }

  export function computeNewMajorVersion(contentId:any) {
    let content = contentId.split(":");
    let contentKeyBaseId = content[0];
    let version = parseFloat(content[1]);
 
    let incrementedNumber;
    if (Number.isInteger(version)) {
        incrementedNumber = (version + 1).toFixed(0);
    } else {
        incrementedNumber = (version + 1).toString();
    }
 
    let updatedContentId = contentKeyBaseId + ":" + incrementedNumber;
    if (Number.isInteger(version)) {
        updatedContentId += ".000";
    }
 
    return updatedContentId;
}

export function pad(number: number, length: number): string {
  let str = '' + number;
  while (str.length < length) {
    str = '0' + str;
  }
  return str;
}

export function calculateMaxBlockSize(fileSizeInBytes: number): number {
  const maxBlockSizeLimit = 5000 * 1024 * 1024; // 5,000 MiB in bytes // Supported by Azure Storage
  let adjustedBlockSize = 5 * 1024 * 1024;
  if (fileSizeInBytes < 1073741824) {
    // Less than 1 GB
    // Condition for files less than 1 GB
    adjustedBlockSize = 5 * 1024 * 1024; // 5MiB
  } else if (fileSizeInBytes < 2147483648) { //1312264066
    // 2 GB
    // Condition for files between 1 GB and 2 GB
    adjustedBlockSize = 10 * 1024 * 1024; // 10MiB
  } else if (fileSizeInBytes < 5368709120) {
    // 5 GB
    // Condition for files between 2 GB and 5 GB
    adjustedBlockSize = 15 * 1024 * 1024; // 10MiB
  } else if (fileSizeInBytes < 7516192768) {
    // 7 GB
    // Condition for files between 5 GB and 7 GB
    adjustedBlockSize = 20 * 1024 * 1024; // 10MiB
  } else if (fileSizeInBytes < 9764456704) {
    // 9 GB
    // Condition for files between 7 GB and 9 GB
    adjustedBlockSize = 30 * 1024 * 1024; // 10MiB
  } else if (fileSizeInBytes < 11906150656) {
    // 11 GB
    // Condition for files between 9 GB and 11 GB
    adjustedBlockSize = 40 * 1024 * 1024; // 10MiB
  } else {
    // Condition for files larger than 11 GB
    adjustedBlockSize = 50 * 1024 * 1024; // 10MiB
  }
  adjustedBlockSize = 5 * 1024 * 1024;
  return adjustedBlockSize;
}