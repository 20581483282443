<div *ngIf="showBanner">
    <lib-status-message></lib-status-message>
</div> 
<div class="group-3">
    <div class="col-2 close-div">
        <i class="material-icons-outlined cross" mat-dialog-close>close</i>
    </div>
    <form [formGroup]="propertyForm">
        <div *ngIf="!editMode" class="group-2">
            <p class="metadata">Metadata</p>
        </div>
        
        <div *ngIf="!editMode" class="form-full-width mb-2 form-block" >
            <div class="form-header">Filename</div>
            <div class="subtitle-1">{{ fileName }} </div>
        </div>

        <div *ngIf="!editMode" class="form-full-width mb-2 form-block">
            <div class="form-header">Artifact ID</div>
            <div class="subtitle-1">{{ artifactId }}</div>
        </div>
        <div *ngIf="!editMode">
            <div class="flex-form">
                <div class="form-full-width-1 mb-2 form-block" *ngFor="let controlName of propertyForm.controls | keyvalue">
                <div class="form-header">{{ controlName.key | formatControlName}}</div>
                <div class="subtitle-1">{{ controlName.value.value }}</div>   
                </div>
            </div>
            <button  class="primary close" (click)="close()">Close</button>
        </div>
        <!-- class="input-field-task input-field example-box"-->
        <div *ngIf="editMode">
            <div class="header-div">
                <div class="header" style="color:#FFAD19;font-family: Helvetica">EDIT FILE</div>
            </div>
            <div class="upload-div">
                <span class="mat-button-wrapper">
                    <label class="asterix fw-bold">File</label><br />
                    <label class="mb-1">Upload a video or the most recent version</label><br />
                    <button mat-raised-button class="upload-btn" [disabled]="disableUploadBtn"
                        (click)="fileInput.click()">
                        <span class="mat-button-wrapper">
                            <mat-icon class="btn-icon">cloud_upload</mat-icon>
                            BROWSE
                        </span>
                    </button>
                    <input type="file" #fileInput style="display: none" (change)="handleFileSelect($event)">
                </span>
                <!-- <span *ngIf="selectedFile" class="selected-file-name" style="margin-left:20px">
                    {{ selectedFile.name }}
                </span> -->
                <span *ngIf="selectedFile || fileName" class="selected-file-name" style="margin-left: 20px">
                    {{ selectedFile ? selectedFile.name : fileName }}
                  </span>
            </div>

            <div class="upload-thumbnail-div" *ngIf="selectedDocType === 'TSVIDEO'">
                <span class="mat-button-wrapper">
                    <label class="asterix fw-bold">Image</label><br />
                    <label class="mb-1">Upload an image for the video thumbnail</label><br />
                    <button mat-raised-button class="upload-btn" [disabled]="disableUploadBtn"
                        (click)="imageFileInput.click()">
                        <span class="mat-button-wrapper">
                            <mat-icon class="btn-icon">cloud_upload</mat-icon>
                            BROWSE
                        </span>
                    </button>
                    <input type="file" #imageFileInput style="display: none" (change)="handleThumbnailFileUpload($event)">
                </span>
                <!-- <span *ngIf="selectedThumbnailFile" class="selected-file-name">
                    {{ selectedThumbnailFile.name }}
                </span> -->
                <span *ngIf="thumbnailFileName" class="selected-file-name" style="margin-left: 20px">
                    {{ thumbnailFileName }}
                  </span>
            </div>

            <!-- <div class="input-flex-form"> -->
                <div class="input-form-full-widthqw"  *ngFor="let control of propertyForm.controls | keyvalue">
                    <mat-form-field  appearance="fill" class="form-input">
                        <mat-label class="input-label large-label">{{ control.key | formatControlName }}</mat-label>
                        <ng-container *ngIf="control.key !== 'model'; else modelSelect">
                            <input 
                                matInput 
                                [placeholder]="control.key | formatControlName" 
                                [formControlName]="control.key"
                                [value]="control.value.value"
                            />
                        </ng-container>
                        <ng-template #modelSelect>
                            <mat-select 
                                [placeholder]="control.key | formatControlName" 
                                [formControlName]="control.key"
                                (selectionChange)="selectedModel = $event.value">
                                <mat-option [value]="model.modelName" *ngFor="let model of models">{{ model.modelName }}</mat-option>
                            </mat-select>
                        </ng-template>
                    </mat-form-field>
                </div>
            <!-- </div> -->
            
            <button class="primary close" (click)="handleEditSubmit()"         
                    [disabled]="!propertyForm.valid || !hasFormChanged()">
                    Submit
            </button>
        </div> 
    </form>     
</div>