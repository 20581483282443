<div class="video-row">
    <mat-card class="video-card m-3" *ngFor="let video of videos">
        <div class="image-container">
            <img mat-card-image src="../../../assets/Boeing-Logo.png">
            <span class="icon-overlay">
                <i class="material-icons-outlined">play_circle_outline</i>
            </span>
        </div>
        <mat-card-content>
            <div class="title-div">{{video.metadata.title}}</div>
            <div class="description-div">{{ video.metadata.description }}</div>
        </mat-card-content>
        <mat-card-actions>
            <button mat-button class="watch-btn" (click)="openVideoDialog(video)">
                <span class="material-symbols-outlined">
                    arrow_forward
                </span>
                WATCH</button>
        </mat-card-actions>
    </mat-card>
</div>