import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors, ValidatorFn, FormGroupDirective, NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { BtlSharedLibraryService } from 'btl-shared-library';
import { VideoService } from 'src/app/services/video-service';
import { ProgressItem } from 'src/app/models/access.model';
import { removeProgressItem, sortVideosByCreatedDate, updateProgressItem } from 'src/app/utils/video-utils';
import { ErrorStateMatcher } from '@angular/material/core';
export interface DialogData {
  files: any;
}

@Component({
  selector: 'app-file-upload-dialog',
  templateUrl: './file-upload-dialog.component.html',
  styleUrls: ['./file-upload-dialog.component.scss']
})


export class FileUploadDialogComponent implements OnInit, ErrorStateMatcher {

  fileUploadForm?: FormGroup;
  sasUrl: string = '';
  maxBlockSize: number = 5 * 1024 * 1024;
  numberOfBlocks: number = 1;
  selectedFile: File | null = null;
  currentFilePointer: number = 0;
  totalBytesRemaining: number = 0;
  blockIds: string[] = [];
  blockIdPrefix: string = 'block-';
  submitUri: string | null = null;
  bytesUploaded: number = 0;
  uploadProgress: string = '0.00';
  fileName: any = "";
  uploadMetaData: any;
  progress: any;
  uploadMetadataForm: any;
  disableUploadBtn: boolean = false;
  wordCount: number = 0;
  wordLimit: number = 300;
  wordProgress: string = '0.00';
  progressItems: ProgressItem[] = []
  videos: any[] = [];
  // TITLE_REGEX = '^(?! )[A-Za-z0-9,\'"();:° -]+$';
  TITLE_REGEX = /^[A-Za-z0-9][A-Za-z0-9,!@#$%^&*()_+{}\[\]:;'"<>,.?/~` -]*$/;
  VIDEO_TYPES = ['flv', 'mxf', 'gxf', 'ps', '3gp', '3gpp', 'mpg', 'wmv', 'asf', 'avi', 'mp4', 'm4a', 'm4v', 'isma', 'ismv', 'dvr-ms', 'mkv', 'wav', 'mov'];

  // DESCRIPTION_REGEX = '[^[~*<>:+|{}\\]]+$';
  DESCRIPTION_REGEX = /^[A-Za-z0-9][A-Za-z0-9,!@#$%^&*()_+{}\[\]:;'"<>,.?/~` -]*$/;
  isUploadVideo: boolean = false;
  showBanner: boolean = false;
  isErrorState(control: AbstractControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.invalid && (control.dirty || control.touched));
  }

  constructor(
    private dialogRef: MatDialogRef<FileUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private sharedDataService: SharedDataService,
    private btlSharedLibraryService: BtlSharedLibraryService,
    private videoService: VideoService
  ) { }

  ngOnInit(): void {
    this.initUploadFormGroup();

    console.log("fileUploadForm:", this.fileUploadForm);
    this.sharedDataService.progress$.subscribe(progress => {
      this.progress = progress
      console.log("PROGRESS DATA IN FILE:", this.progress);
    });

    this.sharedDataService.progressList$.subscribe(progressItems => {
      this.progressItems = progressItems;
    })
    if (this.progressItems == null) {
      this.progressItems = [];
    }

    this.sharedDataService.videosList$.subscribe(videos => {
      if (videos != null) {
        this.videos = videos;
      }
    })
  }

  generateFormData() {
    return {
      contentKey: this.videoService.generateRandomUUID('TSVIDEO'),
      title: this.title ?.value,
      fileName: this.fileName,
      description: this.description ?.value,
      fileType: this.selectedFile!.type,
    };
  }
  cancelUpload() {
    console.log("close ref")
    this.dialogRef.close();
  }

  handleFileSelect(event: any): void {
    const files = event.target.files;
    this.selectedFile = files[0];

    if (this.selectedFile != null) {
      this.totalBytesRemaining = this.selectedFile.size;
      this.fileName = this.selectedFile.name;
      this.maxBlockSize = this.calculateMaxBlockSize(this.selectedFile.size);
    }
    this.validateFileExtention(event)

    this.fileUploadForm = this.formBuilder.group({
      title: [this.title ?.value, [Validators.required, Validators.pattern(this.TITLE_REGEX)]],
      description: [this.description ?.value, [Validators.required, Validators.pattern(this.TITLE_REGEX)]],
      file: [this.selectedFile ?.name, [Validators.required]]
    });
  }

  validateFileExtention(event: any) {
    this.isUploadVideo = this.VIDEO_TYPES.includes(this.fileName.split('.').pop());
    if (!this.isUploadVideo) {
      console.log("Files:pre:", event.target.files)
      event.target.value = null;
      this.selectedFile = null;
      console.log("Files:", event.target.files)
      this.initUploadFormGroup();
      this.showBanner = true;
      this.sharedDataService.updateShowUploadBanner(false);
      this.btlSharedLibraryService.updateStatusMessage(true, "Invalid file extension. Allowed extensions are mp4, wav, mov, avi, flv, mpeg, mkv", false);
    }

    setTimeout(() => {
      this.showBanner = false;
    }, 4000)
  }

  initUploadFormGroup() {
    this.fileUploadForm = this.formBuilder.group({
      title: [null, [Validators.required, Validators.pattern(this.TITLE_REGEX)]],
      description: [null, [Validators.required, Validators.pattern(this.TITLE_REGEX)], this.wordLimitValidator(300)],
      file: [null, [Validators.required]]
    });
  }
  trimValidator(control: AbstractControl): ValidationErrors | null {
    console.log("control:", control)
    if (control.value && typeof control.value === 'string') {
      const trimmedValue = control.value.trim();
      if (trimmedValue !== control.value) {
        control.patchValue(trimmedValue);
      }
    }
    return null;
  }

  uploadFileInBlocks(): void {
    this.dialogRef.close();

    this.uploadMetadataForm = this.generateFormData();
    console.log("Initiate UPLOAD File AZ::", this.uploadMetadataForm)
    this.sharedDataService.updateUploadMetaData(this.uploadMetadataForm);
    console.log("FILE:", this.selectedFile)

    /**allow parallel upload progress bar*/
    this.uploadMetadataForm = this.generateFormData();
    console.log("Initiate UPLOAD File AZ::", this.uploadMetadataForm)
    this.sharedDataService.updateUploadMetaData(this.uploadMetadataForm);
    let progressItem = new ProgressItem();
    progressItem.uploading = true;
    progressItem.progress = 1;
    progressItem.uploadMetaData = this.uploadMetadataForm;
    progressItem.progressText = "Uploading ...";
    this.progressItems.push(progressItem);
    console.log("Progress items:", this.progressItems)
    this.sharedDataService.updateProgressList(this.progressItems);

    if (!this.selectedFile) {
      return;
    }
    if (this.selectedFile != null) {
      this.videoService.initiateVideoUploadAz(this.uploadMetadataForm).subscribe(
        (initiateResponse) => {
          if (initiateResponse.status === '201 CREATED') {
            this.sasUrl = initiateResponse.data.storageUrl;
            this.submitUri = this.generateSubmitUri(this.fileName, this.sasUrl);
            console.log("Upload blob SAS URL FETCHED:", this.submitUri);
            this.uploadChunks();
          }
        },
        (initiateError) => {
          console.error('Error initiating video upload:', initiateError);
          let e = initiateError.error;
          console.error(e);
          const errorMessage = e.error && e.error.message ? e.error.message : "An error occurred during the request.";
          this.btlSharedLibraryService.updateStatusMessage(true, errorMessage, false);
        }
      );
    }
  }
  uploadChunks(): void {
    if (this.totalBytesRemaining > 0) {
      const fileContent = this.selectedFile!.slice(
        this.currentFilePointer,
        this.currentFilePointer + this.maxBlockSize
      );
      const blockId = this.blockIdPrefix + this.pad(this.blockIds.length, 6);
      this.blockIds.push(btoa(blockId));
      console.log("######## UPLOAD BLOCK::", blockId)
      let uri = this.submitUri + '&comp=block&blockid=' + this.blockIds[this.blockIds.length - 1];
      const formData = new FormData();
      formData.append('file', fileContent);

      this.http.put(uri, fileContent, {
        headers: new HttpHeaders({
          'x-ms-blob-type': 'BlockBlob',
        }),
        observe: 'response'
      }).subscribe({
        next: (response: HttpResponse<any>) => {
          if (response.status === 201) {
            this.bytesUploaded += fileContent.size;
            console.log("********* BYTES UPLOADED:", this.bytesUploaded, response.status);
            const percentComplete = ((this.bytesUploaded / this.selectedFile!.size) * 100).toFixed(2);
            this.uploadProgress = percentComplete;
            let currentUploadingState = this.bytesUploaded <= this.selectedFile!.size
            let currentProgress = parseInt(this.uploadProgress);
            this.progressItems = updateProgressItem(this.progressItems, this.uploadMetadataForm.contentKey, currentUploadingState, currentProgress, "Uploading ...")
            console.log("Progress items after first update:", this.progressItems)
            this.sharedDataService.updateProgressList(this.progressItems);

            this.currentFilePointer += this.maxBlockSize;
            this.totalBytesRemaining -= this.maxBlockSize;
            if (this.totalBytesRemaining < this.maxBlockSize) {
              this.maxBlockSize = this.totalBytesRemaining;
            }
            this.uploadChunks();
          }
        },
        error: (error: HttpErrorResponse) => {
          console.error('Upload Error:', error);
          this.btlSharedLibraryService.updateStatusMessage(true, error.statusText, false);
          this.handleProgressItems();
        }
      });
    } else {
      console.log("######## UPLOAD BLOCK FINISHED::", this.blockIds)
      this.commitBlockList();
    }
  }

  commitBlockList(): void {
    console.log("######## COMMIT BLOCK START::", this.blockIds)
    const requestBody = '<?xml version="1.0" encoding="utf-8"?><BlockList>' +
      this.blockIds.map(id => `<Latest>${id}</Latest>`).join('') +
      '</BlockList>';
    this.http.put(`${this.submitUri}&comp=blocklist`, requestBody, {
      headers: new HttpHeaders({
        'x-ms-blob-content-type': this.selectedFile!.type,
      }),
      observe: 'response'
    }).subscribe({
      next: (response: HttpResponse<any>) => {
        console.log('Block list committed successfully:', response);
        console.log('Response Status:', response.status);
        console.log('Response Headers:', response.headers);
        this.btlSharedLibraryService.updateStatusMessage(true, `${this.fileName} uploaded to storage successfully`, true);
        this.handleProgressItems();
      },
      error: (error: HttpErrorResponse) => {
        console.error('Commit Block List Error:', error);
        console.log('Error Status:', error.statusText);
        console.log('Error Message:', error.message);
        console.log('Error Headers:', error.headers);
        this.btlSharedLibraryService.updateStatusMessage(true, error.statusText, false);
        this.sharedDataService.updateUploadingState(false);
        this.handleProgressItems();
      }
    });
  }

  completeCmsUpload() {
    this.uploadMetadataForm = { ...this.uploadMetadataForm, storageUrl: this.submitUri }
    this.videoService.completeVideoUploadAz(this.uploadMetadataForm).subscribe({
      next: (response: any) => {
        console.log("CompleteVideoUploadAz Response:", response)
        this.btlSharedLibraryService.updateStatusMessage(true, `${response.data.message}`, true);
        this.sharedDataService.updateUploadingState(false);
        this.refreshArtifactList(this.uploadMetadataForm.contentKeyId)
      },
      error: (error: any) => {
        console.log("Complete Az Copy Upload error:", error.error)
        this.btlSharedLibraryService.updateStatusMessage(true, error.error.message, false);
        this.sharedDataService.updateUploadingState(false);
        this.handleProgressItems();
      }
    })
  }

  /**
   * this method will be executed to get the data of recent upload peroformed by async az-upload api
   * if response is 200 , the contentKey has been uploaded ; append to the existing list; 
   * until then keep calling this api
   */
  refreshArtifactList(contentKey: string) {
    console.log("Refresh Artifact List for:", contentKey);
    this.videoService.fetchMetaForADocument('TSVIDEO', contentKey).subscribe({
      next: (response: any) => {
        if (response.status === '200 OK') {
          // Perform actions with the response
          console.log('Response received:', response);
          this.refreshVideoList();
          this.btlSharedLibraryService.updateStatusMessage(true, `${response.data[0].cmsObjectInfo.docName} Available`, true);
        } else {
          // Call the API recursively until a 200 response is received
          this.refreshArtifactList(contentKey);
        }
      },
      error: (error: any) => {
        console.error('Error occurred While resfreshing Artifact List:', error);
        this.refreshArtifactList(contentKey);
      }
    });
  }

  refreshVideoList(): void {
    this.videoService.getAllVideosFromCms('tsvideo').subscribe({
      next: (response) => {
        console.log(response.data)
        this.videos = response.data.data;
        this.videos = sortVideosByCreatedDate(this.videos, false)
        this.sharedDataService.updateVideosList(this.videos);
        console.log("Videos:", this.videos)
      },
      error: (error) => {
        console.error('Error fetching videos:', error);
      }
    });
  }

  pad(number: number, length: number): string {
    let str = '' + number;
    while (str.length < length) {
      str = '0' + str;
    }
    return str;
  }

  private generateSubmitUri(fileName: string, sasUrl: string): string {
    console.log("FILE NAME:", fileName);
    console.log("SAS URL:", sasUrl);
    const indexOfQueryStart = sasUrl.indexOf('?');
    return sasUrl.substring(0, indexOfQueryStart) + '/' + fileName + sasUrl.substring(indexOfQueryStart);
  }

  private calculateMaxBlockSize(fileSizeInBytes: number): number {
    const maxBlockSizeLimit = 5000 * 1024 * 1024; // 5,000 MiB in bytes // Supported by Azure Storage
    let adjustedBlockSize = 5 * 1024 * 1024;
    if (fileSizeInBytes < 1073741824) {
      // Less than 1 GB
      // Condition for files less than 1 GB
      adjustedBlockSize = 5 * 1024 * 1024; // 5MiB
    } else if (fileSizeInBytes < 2147483648) { //1312264066
      // 2 GB
      // Condition for files between 1 GB and 2 GB
      adjustedBlockSize = 10 * 1024 * 1024; // 10MiB
    } else if (fileSizeInBytes < 5368709120) {
      // 5 GB
      // Condition for files between 2 GB and 5 GB
      adjustedBlockSize = 15 * 1024 * 1024; // 10MiB
    } else if (fileSizeInBytes < 7516192768) {
      // 7 GB
      // Condition for files between 5 GB and 7 GB
      adjustedBlockSize = 20 * 1024 * 1024; // 10MiB
    } else if (fileSizeInBytes < 9764456704) {
      // 9 GB
      // Condition for files between 7 GB and 9 GB
      adjustedBlockSize = 30 * 1024 * 1024; // 10MiB
    } else if (fileSizeInBytes < 11906150656) {
      // 11 GB
      // Condition for files between 9 GB and 11 GB
      adjustedBlockSize = 40 * 1024 * 1024; // 10MiB
    } else {
      // Condition for files larger than 11 GB
      adjustedBlockSize = 50 * 1024 * 1024; // 10MiB
    }
    adjustedBlockSize = 5 * 1024 * 1024;
    return adjustedBlockSize;
  }


  get title() { return this.fileUploadForm ?.get('title'); }
  get description() { return this.fileUploadForm ?.get('description'); }

  wordLimitValidator(limit: number) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value) {
        const words = control.value.split(/\s+/).length;
        return words > limit ? { 'wordLimitExceeded': true } : null;
      }
      return null;
    };
  }

  updateWordCount() {
    const descriptionValue = this.description ?.value;
    const characterCount = descriptionValue ? descriptionValue.length : 0;

    if (characterCount > this.wordLimit) {
      this.description ?.setValue(descriptionValue.substring(0, this.wordLimit));
    }

    this.wordCount = characterCount;
    this.wordProgress = characterCount <= this.wordLimit ? characterCount.toString() : `${this.wordLimit}+`;
  }

  countWords(text: string): number {
    const words = text.split(/\s+/).filter(word => word.length > 0);
    return words.length;
  }

  /**=========================================== New Upload Functionality ==================================================== */

  uploadFileInBlocksNew(): void {
    this.dialogRef.close();
    this.sharedDataService.updateUploadingState(true);
    this.sharedDataService.updateShowUploadBanner(true);
    this.sharedDataService.updateUploadingProgress(1);
    this.sharedDataService.updateProgressText("Uploading ...");

    /**allow parallel upload progress bar*/
    this.uploadMetadataForm = this.generateFormData();
    console.log("Initiate UPLOAD File AZ::", this.uploadMetadataForm)
    this.sharedDataService.updateUploadMetaData(this.uploadMetadataForm);
    let progressItem = new ProgressItem();
    progressItem.uploading = true;
    progressItem.progress = 1;
    progressItem.uploadMetaData = this.uploadMetadataForm;
    progressItem.progressText = "Uploading ...";
    this.progressItems.push(progressItem);
    console.log("Progress items:", this.progressItems)
    this.sharedDataService.updateProgressList(this.progressItems);

    console.log("FILE:", this.selectedFile)
    if (!this.selectedFile) {
      return;
    }
    if (this.selectedFile != null) {
      this.uploadBlockChunks();
    }
  }

  uploadBlockChunks() {
    if (this.totalBytesRemaining > 0) {
      const fileContent = this.selectedFile!.slice(
        this.currentFilePointer,
        this.currentFilePointer + this.maxBlockSize
      );
      const blockId = this.blockIdPrefix + this.pad(this.blockIds.length, 6);
      this.blockIds.push(btoa(blockId));
      console.log("######## UPLOAD BLOCK::", blockId)
      let latestBlockId = this.blockIds[this.blockIds.length - 1];
      this.videoService.uploadBlockChunk(this.uploadMetadataForm.contentKey, latestBlockId, this.fileName, fileContent)
        .subscribe({
          next: (response: HttpResponse<any>) => {
            if (response.status === 201) {
              this.bytesUploaded += fileContent.size;
              console.log("********* BYTES UPLOADED:", this.bytesUploaded, response.status);
              const percentComplete = ((this.bytesUploaded / this.selectedFile!.size) * 100).toFixed(2);
              this.sharedDataService.updateUploadingState(this.bytesUploaded <= this.selectedFile!.size);
              this.uploadProgress = percentComplete;
              this.sharedDataService.updateUploadingProgress(parseInt(this.uploadProgress));

              let currentUploadingState = this.bytesUploaded <= this.selectedFile!.size
              let currentProgress = parseInt(this.uploadProgress);
              this.progressItems = updateProgressItem(this.progressItems, this.uploadMetadataForm.contentKey, currentUploadingState, currentProgress, "Uploading ...")
              console.log("Progress items after first update:", this.progressItems)
              this.sharedDataService.updateProgressList(this.progressItems);

              this.currentFilePointer += this.maxBlockSize;
              this.totalBytesRemaining -= this.maxBlockSize;
              if (this.totalBytesRemaining < this.maxBlockSize) {
                this.maxBlockSize = this.totalBytesRemaining;
              }
              this.uploadBlockChunks();
            }
          },
          error: (error: HttpErrorResponse) => {
            console.error('Upload Error:', error);
            this.btlSharedLibraryService.updateStatusMessage(true, error.statusText, false);
            this.handleProgressItems();
            this.sharedDataService.updateUploadingState(false);
          }
        });
    } else {
      console.log("######## UPLOAD BLOCK FINISHED::", this.blockIds)
      this.completeFileUpload();
    }
  }

  completeFileUpload() {
    console.log("######## COMMIT BLOCK START::", this.blockIds)
    const blockListRequest = '<?xml version="1.0" encoding="utf-8"?><BlockList>' +
      this.blockIds.map(id => `<Latest>${id}</Latest>`).join('') +
      '</BlockList>';

    this.uploadMetadataForm = { ...this.uploadMetadataForm, blockList: blockListRequest }
    this.sharedDataService.updateProgressText("Completing Upload...");
    this.progressItems = updateProgressItem(this.progressItems, this.uploadMetadataForm.contentKey, true, parseInt(this.uploadProgress), "Completing Upload...")
    this.sharedDataService.updateProgressList(this.progressItems);
    this.videoService.commitBlockList(this.uploadMetadataForm)
      .subscribe({
        next: (response: HttpResponse<any>) => {
          console.log('Block list committed successfully:', response);
          console.log('Response Status:', response.status);
          console.log('Response Headers:', response.headers);
          this.sharedDataService.updateUploadingState(false);
          this.btlSharedLibraryService.updateStatusMessage(true, `${this.fileName} uploaded to storage successfully`, true);
          this.handleProgressItems();
          this.refreshArtifactList(this.uploadMetadataForm.contentKey);
        },
        error: (error: HttpErrorResponse) => {
          console.error('Commit Block List Error:', error);
          console.log('Error Status:', error.statusText);
          console.log('Error Message:', error.message);
          console.log('Error Headers:', error.headers);
          this.btlSharedLibraryService.updateStatusMessage(true, error.statusText, false);
          this.handleProgressItems();
          this.sharedDataService.updateUploadingState(false);
        }
      });
  }

  handleProgressItems() {
    this.progressItems = updateProgressItem(this.progressItems, this.uploadMetadataForm.contentKey, false, 0, "")
    this.sharedDataService.updateProgressList(this.progressItems);
    this.progressItems = removeProgressItem(this.progressItems, this.uploadMetadataForm.contentKey)
    this.sharedDataService.updateProgressList(this.progressItems);
  }
}
