<div *ngFor="let progressItem of progressList">
    <div class="video-list-view align-items-center upload-section" *ngIf="progressItem.uploading">
        <div class="video-content-container">
            <div class="col-6 m-0 p-0">
                <div class="video-title cursor-pointer" id="title">
                    <p>{{progressItem.uploadMetaData.fileName}}</p>
                </div>
                <span id="progress">{{progressItem.progressText}} {{progressItem.progress}}% complete</span>
                <ng-template #retry>
                    <span id="retry-message" style="color: '#E4002D';">Error loading. Please click the retry button to resume
                        the uploading process.</span>
                </ng-template>
            </div>
            <div style="margin-left: -128px;">
                <div class="col-6 m-0 p-0 d-flex justify-content-end align-items-center">
                    <span class="uploadProgress">
                        <span class="determinateBar" [style.width.%]="progressItem.progress">
                        </span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>