<!--status message-->
<div *ngIf="showPlayerStatus">
    <lib-status-message></lib-status-message>
  </div>
    <div class="azure-cdn-mp4" style="margin-left: 100px;">
        <p class="player-title">Dynamic Streaming using MediaSource</p>
        <video #videoElement controls preload="auto" width="640" height="360" data-setup='{}' [poster]="imageUrl"></video>
    </div>
  <div class="header-div" id="header-id" [style.margin-left.px]="marginValue">
      <div  style="font-size: 14px">
        Currently Playing:
        <br/>
        <span style="font-weight: bold">{{ video.metadata.title }}</span>
      </div> 
      <div class="cross">
          <span>
              <mat-icon class ="btn-icon" (click)="cancelPlayer()">highlight_off</mat-icon>
          </span>
      </div>
  </div>
  <div class="video-player-dialog">
    <div>
      <video
        id="video-player"
        class="video-js"
        controls
        preload="auto"
        width="640"
        height="360"
        data-setup="{}"
        [poster]="imageUrl"
      >
        <!-- <source type="application/x-mpegURL"> -->
        <!-- <source [src]="video.streamingUrl" /> -->
        <!-- <source src="https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8" type="application/x-mpegURL"/> -->
        <source [src]="defaultStreamingUrl"/>
        <!-- Fallback for non-supported browsers -->
        <p class="vjs-no-js">
          To view this video, please enable JavaScript, and consider upgrading to
          a web browser that supports HTML5 video
        </p>
      </video>
      <div class="description-div">
          <div class="description-header">Description</div>
          <div>{{ videoMetaData["Description"] }}</div>
      </div>
    </div>
    <div class="video-metadata">
      <div class="mx-2">
        <div class="artifact-information">METADATA </div>
        <div class="metadata-box" style="max-height: 300px; overflow-y: auto;">
          <div *ngFor="let key of metadataOrder">
            <div class="metadata-entry" *ngIf="videoMetaData[key] && key!== 'Description'">
              <div class="metadata-header">{{ key }}</div>
              <div>{{ videoMetaData[key] }}</div>
            </div>
          </div>        
        </div>
      </div>
    </div>
  </div>
  