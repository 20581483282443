<div class="download-progress-wrapper" *ngIf="downloading">
  <div class="download-progress-container">
    <mat-progress-spinner 
        mode="indeterminate" 
        class="custom-spinner"
        [strokeWidth]="2" [diameter]="30" [matTooltip]="getTooltipText()"
      matTooltipPosition="right">
    </mat-progress-spinner>
    <button mat-icon-button class="cancel-button" (click)="onCancelDownload()" [matTooltip]="getTooltipText()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>