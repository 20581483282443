import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, ValidationErrors, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BtlSharedLibraryService } from 'btl-shared-library';
import { ProgressItem } from 'src/app/models/access.model';
import { DocGroup, Models } from 'src/app/models/doc-group.model';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { SpinnerOverlayService } from 'src/app/services/spinner-overlay-service';
import { VideoService } from 'src/app/services/video-service';
import { pad, removeProgressItem, sortVideosByCreatedDate, updateProgressItem } from 'src/app/utils/video-utils';

export interface DialogData {
  files: any;
}
interface PropertyDefinition {
  propertyName: string;
  propertyType: string;
  repeat: string;
  restriction: string;
}

@Component({
  selector: 'app-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.scss']
})
export class UploadDialogComponent implements OnInit {


  fileUploadForm?: FormGroup;
  sasUrl: string = '';
  maxBlockSize: number = 5 * 1024 * 1024;
  numberOfBlocks: number = 1;
  selectedFile: File | null = null;
  selectedThumbnailFile: File | null = null;
  currentFilePointer: number = 0;
  totalBytesRemaining: number = 0;
  blockIds: string[] = [];
  blockIdPrefix: string = 'block-';
  submitUri: string | null = null;
  bytesUploaded: number = 0;
  uploadProgress: string = '0.00';
  fileName: any = "";
  thumbnailFileName: any = "";
  uploadMetaData: any;
  progress: any;
  uploadMetadataForm: any;
  disableUploadBtn: boolean = false;
  wordCount: number = 0;
  wordLimit: number = 300;
  wordProgress: string = '0.00';
  progressItems: ProgressItem[] = []
  models: Models[];
  propertyDefinitions: any;
  metadata: any = {};
  popupData: any;
  selectedDocType!: string;
  artifactId: any;
  videos: any[] = [];
  // TITLE_REGEX = '^(?! )[A-Za-z0-9,\'"();:° -]+$';
  TITLE_REGEX = /^[A-Za-z0-9][A-Za-z0-9,!@#$%^&*()_+{}\[\]:;'"<>,.?/~` -]*$/;
  VIDEO_TYPES = ['zip','flv', 'mxf', 'gxf', 'ps', '3gp', '3gpp', 'mpg', 'wmv', 'asf', 'avi', 'mp4', 'm4a', 'm4v', 'isma', 'ismv', 'dvr-ms', 'mkv', 'wav', 'mov'];
  IMAGE_TYPES = ['jpg','jpeg','png','PNG','gif','bmp','tiff','tif','webp','svg','ico','heif','heic','raw'];

  // DESCRIPTION_REGEX = '[^[~*<>:+|{}\\]]+$';
  DESCRIPTION_REGEX = /^[A-Za-z0-9][A-Za-z0-9,!@#$%^&*()_+{}\[\]:;'"<>,.?/~` -]*$/;
  isUploadVideo: boolean = false;
  isUploadThumbnailImage: boolean = false;
  showBanner: boolean = false;
  retryCount : number = 0;


  isErrorState(control: AbstractControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.invalid && (control.dirty || control.touched));
  }

  docGroups: DocGroup[] = [
    { value: 'tsvideo', key: 'TSVIDEO' },
    { value: 'mtspkg', key: 'MTS' },
    { value: 'ftvideo', key: 'FTVIDEO' },
    { value: 'mtvideo', key: 'MTVIDEO' }
  ];
  selectedModel: string | { modelName: string } = "";

  constructor(
    private dialogRef: MatDialogRef<UploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private sharedDataService: SharedDataService,
    private btlSharedLibraryService: BtlSharedLibraryService,
    private videoService: VideoService,
    private spinnerOverlayService: SpinnerOverlayService,
  ) {
    //this.propertyForm = new FormGroup({});
    this.models = this.videoService.getStaticModels();
    console.log(this.models);

  }

  ngOnInit(): void {
    this.initUploadFormGroup();

    console.log("fileUploadForm:", this.fileUploadForm);
    this.sharedDataService.progress$.subscribe(progress => {
      this.progress = progress
      console.log("PROGRESS DATA IN FILE:", this.progress);
    });

    this.sharedDataService.progressList$.subscribe(progressItems => {
      this.progressItems = progressItems;
    })
    if (this.progressItems == null) {
      this.progressItems = [];
    }

    this.sharedDataService.videosList$.subscribe(videos => {
      if (videos != null) {
        this.videos = videos;
      }
    })
  }

  changeDocType(docType: string): void {
    this.selectedDocType = docType;
    this.loadMetadata(docType)
  }

  loadMetadata(docType: string): void {
    if (docType.toLowerCase() === "tsvideo" || docType.toLowerCase() === "mtvideo" || docType.toLowerCase() === "mtspkg" || docType.toLowerCase() === "ftvideo" ) {
      this.spinnerOverlayService.show();
      this.videoService.getPropertyDefinitions(docType).subscribe((response: any) => {
        //this.propertyDefinitions = response.doctypes[0].definition;
        //this.propertyDefinitions = response.doctypes[0].definition.filter((obj: any) => obj.propertyName !== "model");
        this.propertyDefinitions = this.applyPropertyNameExclusionList(docType,response)
        this.createForm();
        this.spinnerOverlayService.hide();
      }, (error) => {
        console.error('Error fetching data:', error);
        this.propertyDefinitions = [];
        this.spinnerOverlayService.hide();
      })
    }
    else {
      this.spinnerOverlayService.show();
      this.videoService.getMetadataByDocTypeName(docType).subscribe((response: any) => {        
        this.propertyDefinitions = response.doctypes[0].definition.filter((obj: any) => obj.propertyName !== "model");
        this.createForm();
        this.spinnerOverlayService.hide();
      }, (error) => {
        console.error('Error fetching data:', error);
        this.propertyDefinitions = [];
        this.spinnerOverlayService.hide();
      })
    }

  }

  applyPropertyNameExclusionList(docType:any,propertyDefinitions:any){
    let exclusionList = ['keywords','revisionNumber','revisionDate','model'];
    if(docType.toLowerCase() === "mtvideo" || docType.toLowerCase() === "ftvideo"){
      return propertyDefinitions.doctypes[0].definition.filter((obj: any) => !exclusionList.includes(obj.propertyName));
    }else{
      return propertyDefinitions.doctypes[0].definition.filter((obj: any) => obj.propertyName !== "model");
    }    
  }
  createForm(): void {
    this.propertyDefinitions.forEach((def: PropertyDefinition) => {
      const validators = [];
      if (def.restriction === 'Mandatory') {
        validators.push(Validators.required, Validators.pattern(this.TITLE_REGEX));
      }
      this.fileUploadForm?.addControl(def.propertyName, this.formBuilder.control(null, validators));
    });
  }

  generateFormData() {
    // deprecated
    return {
      contentKey: this.videoService.generateRandomUUID('TSVIDEO'),
      title: this.title?.value,
      fileName: this.fileName,
      description: this.description?.value,
      fileType: this.selectedFile!.type,
    };
  }

  generateFormDataForDoctype(doctype: string): any {
    const formData: { [key: string]: any } = {
      contentKey: this.videoService.generateRandomUUID(doctype),
      fileName: this.fileUploadForm?.get('file')?.value,
      fileType: this.selectedFile?.type
    };

    this.propertyDefinitions.forEach((def: PropertyDefinition) => {
      formData[def.propertyName] = this.fileUploadForm?.get(def.propertyName)?.value;
    });
    return formData;
  }

  getMetadataJson(){
    const metadata: { [key: string]: any } = {};
    this.propertyDefinitions.forEach((def: PropertyDefinition) => {
      
      let formValue = this.fileUploadForm?.get(def.propertyName)?.value;

      // Convert ataChapter and ataSection to integer
      if (def.propertyName === 'ataChapter' || def.propertyName === 'ataSection') {
        formValue = formValue ? parseInt(formValue, 10) : 0;
      }
      
      metadata[def.propertyName] = formValue == null ? "" : formValue;
    });
    
    return metadata;
  }

  cancelUpload() {
    this.dialogRef.close();
  }

  handleFileSelect(event: any): void {
    const files = event.target.files;
    this.selectedFile = files[0];

    if (this.selectedFile != null) {
      this.totalBytesRemaining = this.selectedFile.size;
      this.fileName = this.selectedFile.name;
      this.maxBlockSize = this.calculateMaxBlockSize(this.selectedFile.size);
    }
    this.validateFileExtention(event)

    // Update the file control with the selected file's name
    this.fileUploadForm?.patchValue({
      file: this.selectedFile?.name
    });
  }

  handleThumbnailFileUpload(event: any): void {
    const files = event.target.files;
    this.selectedThumbnailFile = files[0];
    if (this.selectedThumbnailFile != null) {
    this.thumbnailFileName = this.selectedThumbnailFile.name;
    }

    this.validateThubnailFileExtention(event)
    console.log("thumbnail image : ", this.thumbnailFileName);
  }

  validateFileExtention(event: any) {
    if (this.selectedDocType === 'mtspkg') {
      this.VIDEO_TYPES.push('zip');
    }
    this.isUploadVideo = this.VIDEO_TYPES.includes(this.fileName.split('.').pop());
    if (!this.isUploadVideo) {
      console.log("Files:pre:", event.target.files)
      event.target.value = null;
      this.selectedFile=null;
      console.log("Files:", event.target.files)
      this.initUploadFormGroup();
      this.showBanner = true;
      this.sharedDataService.updateShowUploadBanner(false);
      this.btlSharedLibraryService.updateStatusMessage(true, "Invalid file extension. Allowed extensions are mp4, wav, mov, avi, flv, mpeg, mkv", false);
    }

    setTimeout(() => {
      this.showBanner = false;
    }, 4000)
  }

  validateThubnailFileExtention(event: any) {
    console.log("inside validation with file name : ", this.thumbnailFileName);
    this.isUploadThumbnailImage = this.IMAGE_TYPES.includes(this.thumbnailFileName.split('.').pop());
    if (!this.isUploadThumbnailImage) {
      console.log("Files:pre:", event.target.files)
      event.target.value = null;
      this.selectedThumbnailFile = null;
      console.log("Files:", event.target.files)
      this.initUploadFormGroup();
      this.showBanner = true;
      this.sharedDataService.updateShowUploadBanner(false);
      this.btlSharedLibraryService.updateStatusMessage(true, "Invalid file extension. Allowed extensions for images are jpeg, jpg, png, gif, tiff, bmp, webp, svg, ico, psd, raw", false);
    }

    setTimeout(() => {
      this.showBanner = false;
    }, 4000)
  }

  initUploadFormGroup() {
    this.fileUploadForm = this.formBuilder.group({
      file: [null, [Validators.required]]
    });
  }
  trimValidator(control: AbstractControl): ValidationErrors | null {
    if (control.value && typeof control.value === 'string') {
      const trimmedValue = control.value.trim();
      if (trimmedValue !== control.value) {
        control.patchValue(trimmedValue);
      }
    }
    return null;
  }

  uploadFileInBlocks(): void {
    this.dialogRef.close();

    this.uploadMetadataForm = this.generateFormData();
    console.log("Initiate UPLOAD File AZ::", this.uploadMetadataForm)
    this.sharedDataService.updateUploadMetaData(this.uploadMetadataForm);
    console.log("FILE:", this.selectedFile)

    /**allow parallel upload progress bar*/
    this.uploadMetadataForm = this.generateFormData();
    console.log("Initiate UPLOAD File AZ::", this.uploadMetadataForm)
    this.sharedDataService.updateUploadMetaData(this.uploadMetadataForm);
    let progressItem = new ProgressItem();
    progressItem.uploading = true;
    progressItem.progress = 1;
    progressItem.uploadMetaData = this.uploadMetadataForm;
    progressItem.progressText = "Uploading ...";
    this.progressItems.push(progressItem);
    console.log("Progress items:", this.progressItems)
    this.sharedDataService.updateProgressList(this.progressItems);

    if (!this.selectedFile) {
      return;
    }
    if (this.selectedFile != null) {
      this.videoService.initiateVideoUploadAz(this.uploadMetadataForm).subscribe(
        (initiateResponse) => {
          if (initiateResponse.status === '201 CREATED') {
            this.sasUrl = initiateResponse.data.storageUrl;
            this.submitUri = this.generateSubmitUri(this.fileName, this.sasUrl);
            console.log("Upload blob SAS URL FETCHED:", this.submitUri);
            this.uploadChunks();
          }
        },
        (initiateError) => {
          console.error('Error initiating video upload:', initiateError);
          let e = initiateError.error;
          console.error(e);
          const errorMessage = e.error && e.error.message ? e.error.message : "An error occurred during the request.";
          this.btlSharedLibraryService.updateStatusMessage(true, errorMessage, false);
        }
      );
    }
  }
  uploadChunks(): void {
    if (this.totalBytesRemaining > 0) {
      const fileContent = this.selectedFile!.slice(
        this.currentFilePointer,
        this.currentFilePointer + this.maxBlockSize
      );
      const blockId = this.blockIdPrefix + pad(this.blockIds.length, 6);
      this.blockIds.push(btoa(blockId));
      console.log("######## UPLOAD BLOCK::", blockId)
      let uri = this.submitUri + '&comp=block&blockid=' + this.blockIds[this.blockIds.length - 1];
      const formData = new FormData();
      formData.append('file', fileContent);

      this.http.put(uri, fileContent, {
        headers: new HttpHeaders({
          'x-ms-blob-type': 'BlockBlob',
        }),
        observe: 'response'
      }).subscribe({
        next: (response: HttpResponse<any>) => {
          if (response.status === 201) {
            this.bytesUploaded += fileContent.size;
            console.log("********* BYTES UPLOADED:", this.bytesUploaded, response.status);
            const percentComplete = ((this.bytesUploaded / this.selectedFile!.size) * 100).toFixed(2);
            this.uploadProgress = percentComplete;
            let currentUploadingState = this.bytesUploaded <= this.selectedFile!.size
            let currentProgress = parseInt(this.uploadProgress);
            this.progressItems = updateProgressItem(this.progressItems, this.uploadMetadataForm.contentKey, currentUploadingState, currentProgress, "Uploading ...")
            console.log("Progress items after first update:", this.progressItems)
            this.sharedDataService.updateProgressList(this.progressItems);

            this.currentFilePointer += this.maxBlockSize;
            this.totalBytesRemaining -= this.maxBlockSize;
            if (this.totalBytesRemaining < this.maxBlockSize) {
              this.maxBlockSize = this.totalBytesRemaining;
            }
            this.uploadChunks();
          }
        },
        error: (error: HttpErrorResponse) => {
          console.error('Upload Error:', error);
          this.btlSharedLibraryService.updateStatusMessage(true, error.statusText, false);
          this.handleProgressItems();
        }
      });
    } else {
      console.log("######## UPLOAD BLOCK FINISHED::", this.blockIds)
      this.commitBlockList();
    }
  }

  commitBlockList(): void {
    console.log("######## COMMIT BLOCK START::", this.blockIds)
    const requestBody = '<?xml version="1.0" encoding="utf-8"?><BlockList>' +
      this.blockIds.map(id => `<Latest>${id}</Latest>`).join('') +
      '</BlockList>';
    this.http.put(`${this.submitUri}&comp=blocklist`, requestBody, {
      headers: new HttpHeaders({
        'x-ms-blob-content-type': this.selectedFile!.type,
      }),
      observe: 'response'
    }).subscribe({
      next: (response: HttpResponse<any>) => {
        console.log('Block list committed successfully:', response);
        console.log('Response Status:', response.status);
        console.log('Response Headers:', response.headers);
        this.btlSharedLibraryService.updateStatusMessage(true, `${this.fileName} uploaded to storage successfully`, true);
        //this.handleProgressItems();
      },
      error: (error: HttpErrorResponse) => {
        console.error('Commit Block List Error:', error);
        console.log('Error Status:', error.statusText);
        console.log('Error Message:', error.message);
        console.log('Error Headers:', error.headers);
        this.btlSharedLibraryService.updateStatusMessage(true, error.statusText, false);
        this.sharedDataService.updateUploadingState(false);
        this.handleProgressItems();
      }
    });
  }

  completeCmsUpload() {
    this.uploadMetadataForm = { ...this.uploadMetadataForm, storageUrl: this.submitUri }
    this.videoService.completeVideoUploadAz(this.uploadMetadataForm).subscribe({
      next: (response: any) => {
        console.log("CompleteVideoUploadAz Response:", response)
        this.btlSharedLibraryService.updateStatusMessage(true, `${response.data.message}`, true);
        this.sharedDataService.updateUploadingState(false);
        this.refreshArtifactList(this.uploadMetadataForm.contentKeyId)
      },
      error: (error: any) => {
        console.log("Complete Az Copy Upload error:", error.error)
        this.btlSharedLibraryService.updateStatusMessage(true, error.error.message, false);
        this.sharedDataService.updateUploadingState(false);
        this.handleProgressItems();
      }
    })
  }

  /**
   * this method will be executed to get the data of recent upload peroformed by async az-upload api
   * if response is 200 , the contentKey has been uploaded ; append to the existing list; 
   * until then keep calling this api
   */
  // deprecated
  refreshArtifactListDeprecated(contentKey: string) {
    // this.btlSharedLibraryService.updateStatusMessage(true, `Awaiting CMS response for ${contentKey}...`, true);
    this.videoService.fetchMetaForADocument(this.selectedDocType, contentKey).subscribe({
      next: (response: any) => {
        if (response.status === '200 OK') {
          // Perform actions with the response
          // this.refreshVideoList();
          this.btlSharedLibraryService.updateStatusMessage(true, `${response.data[0].cmsObjectInfo.docName} Available`, true);
          
          // TODO: API CALL TO CREATE THUMBNAIL AS RENDITION
          console.log("refresh response : ", response);
          if (this.selectedThumbnailFile) {
          this.videoService.createRenditionOnCMS(this.selectedDocType, 'thumbnail', response.data[0].contentKey, this.selectedThumbnailFile)
          .subscribe(
          response => {
            this.btlSharedLibraryService.updateStatusMessage(true, `Thumbnail uploaded successfully`, true);
            console.log('Rendition created successfully:', response);
          },
          error => {
            console.error('Error creating rendition:', error);
          }
        );
      }

          // TODO: API CALL TO CREATE THUMBNAIL AS RENDITION
          // navigate to specific media tab
          this.navigateToIndex()
          
        } else {
          // Call the API recursively until a 200 response is received
          this.refreshArtifactList(contentKey);
        }
      },
      error: (error: any) => {
        this.refreshArtifactList(contentKey);
      }
    });
  }
  refreshArtifactList(contentKey: string) {
    const startTime = Date.now(); 
    const fiveMinutesInMillis = 5 * 60 * 1000;
    const updateProgress = () => {
      const elapsedTime = Date.now() - startTime;
      const percentComplete = ((elapsedTime / fiveMinutesInMillis) * 100).toFixed(2);
      this.uploadProgress = percentComplete;
      let currentUploadingState = elapsedTime <= fiveMinutesInMillis;
      let currentProgress = parseInt(this.uploadProgress);
      
      // Update progress bar items
      this.progressItems = updateProgressItem(
        this.progressItems,
        contentKey,
        currentUploadingState,
        currentProgress,
        "Awaiting CMS Response ..."
      );
      this.sharedDataService.updateProgressList(this.progressItems);
    };

    const attemptRefresh = () => {
      // Update progress at each attempt
        updateProgress();
      // check for elapsed time
      const elapsedTime = Date.now() - startTime;
      if (elapsedTime >= fiveMinutesInMillis) {
        // If 5 minutes have passed, show an error message and exit
        console.log("TIMER END:")
        this.btlSharedLibraryService.updateStatusMessage(false,`Unable to upload ${this.fileName} Retrying.`,true);
        if(this.retryCount<=2){
          this.completeFileUpload();
          this.retryCount = this.retryCount+1;
          console.log("RETRY UPLOAD AFTER FAILED ::",this.retryCount);
        }else{
          // finish upload.
          this.handleProgressItems();
          this.btlSharedLibraryService.updateStatusMessage(false,`Unable to upload ${this.fileName}.`,true);
        }
        return;
      }
      this.videoService.fetchMetaForADocument(this.selectedDocType, contentKey).subscribe({
        next: (response: any) => {
          if (response.status === '200 OK') {
            // Perform actions with the response
            this.btlSharedLibraryService.updateStatusMessage(true,`${response.data[0].cmsObjectInfo.docName} Available`,true);
            this.handleProgressItems();
            // API CALL TO CREATE THUMBNAIL AS RENDITION
            if (this.selectedThumbnailFile) {
              this.videoService
                .createRenditionOnCMS(this.selectedDocType, 'thumbnail', response.data[0].contentKey, this.selectedThumbnailFile)
                .subscribe(
                  (response) => {
                    this.btlSharedLibraryService.updateStatusMessage(true,`Thumbnail uploaded successfully`,true);
                    console.log('Rendition created successfully:', response);
                  },
                  (error) => {
                    console.error('Error creating rendition:', error);
                  }
                );
            }
  
            // Navigate to a specific media tab
            setTimeout(()=>{
              this.navigateToIndex()
              // update the current list
              // this.sharedDataService.updateNewVideo(response.data[0])
            },1000);
          } else {
            // Call the API recursively until a 200 response is received
            setTimeout(attemptRefresh, 1); 
          }
        },
        error: (error: any) => {
          // Retry the API call in case of an error
          //this.handleProgressItems();
          setTimeout(attemptRefresh, 1);
        },
      });
    };
    // Start the first attempt
    attemptRefresh(); 
  }
  
  navigateToIndex(){
    if(this.selectedDocType === 'tsvideo' || this.selectedDocType === 'ftvideo' || this.selectedDocType === 'mtvideo'){
      this.sharedDataService.updateTabIndex(0);
    }else{
      this.sharedDataService.updateTabIndex(1);
    }
  }

  refreshVideoList(): void {
    this.videoService.getAllVideosFromCms(this.selectedDocType).subscribe({
      next: (response) => {
        console.log(response.data)
        this.videos = response.data.data;
        this.videos = sortVideosByCreatedDate(this.videos, false)
        this.sharedDataService.updateVideosList(this.videos);
        console.log("Videos:", this.videos)
      },
      error: (error) => {
        console.error('Error fetching videos:', error);
      }
    });
  }

  pad(number: number, length: number): string {
    let str = '' + number;
    while (str.length < length) {
      str = '0' + str;
    }
    return str;
  }

  private generateSubmitUri(fileName: string, sasUrl: string): string {
    console.log("FILE NAME:", fileName);
    console.log("SAS URL:", sasUrl);
    const indexOfQueryStart = sasUrl.indexOf('?');
    return sasUrl.substring(0, indexOfQueryStart) + '/' + fileName + sasUrl.substring(indexOfQueryStart);
  }

  private calculateMaxBlockSize(fileSizeInBytes: number): number {
    const maxBlockSizeLimit = 5000 * 1024 * 1024; // 5,000 MiB in bytes // Supported by Azure Storage
    let adjustedBlockSize = 5 * 1024 * 1024;
    if (fileSizeInBytes < 1073741824) {
      // Less than 1 GB
      // Condition for files less than 1 GB
      adjustedBlockSize = 5 * 1024 * 1024; // 5MiB
    } else if (fileSizeInBytes < 2147483648) { //1312264066
      // 2 GB
      // Condition for files between 1 GB and 2 GB
      adjustedBlockSize = 10 * 1024 * 1024; // 10MiB
    } else if (fileSizeInBytes < 5368709120) {
      // 5 GB
      // Condition for files between 2 GB and 5 GB
      adjustedBlockSize = 15 * 1024 * 1024; // 10MiB
    } else if (fileSizeInBytes < 7516192768) {
      // 7 GB
      // Condition for files between 5 GB and 7 GB
      adjustedBlockSize = 20 * 1024 * 1024; // 10MiB
    } else if (fileSizeInBytes < 9764456704) {
      // 9 GB
      // Condition for files between 7 GB and 9 GB
      adjustedBlockSize = 30 * 1024 * 1024; // 10MiB
    } else if (fileSizeInBytes < 11906150656) {
      // 11 GB
      // Condition for files between 9 GB and 11 GB
      adjustedBlockSize = 40 * 1024 * 1024; // 10MiB
    } else {
      // Condition for files larger than 11 GB
      adjustedBlockSize = 50 * 1024 * 1024; // 10MiB
    }
    adjustedBlockSize = 5 * 1024 * 1024;
    return adjustedBlockSize;
  }


  get title() { return this.fileUploadForm?.get('title'); }
  get description() { return this.fileUploadForm?.get('description'); }

  wordLimitValidator(limit: number) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value) {
        const words = control.value.split(/\s+/).length;
        return words > limit ? { 'wordLimitExceeded': true } : null;
      }
      return null;
    };
  }

  updateWordCount() {
    const descriptionValue = this.description?.value;
    const characterCount = descriptionValue ? descriptionValue.length : 0;

    if (characterCount > this.wordLimit) {
      this.description?.setValue(descriptionValue.substring(0, this.wordLimit));
    }

    this.wordCount = characterCount;
    this.wordProgress = characterCount <= this.wordLimit ? characterCount.toString() : `${this.wordLimit}+`;
  }

  countWords(text: string): number {
    const words = text.split(/\s+/).filter(word => word.length > 0);
    return words.length;
  }

  /**=========================================== New Upload Functionality ==================================================== */

  uploadFileInBlocksNew(doctype: string): void {
    console.log("Uplaod File for:", doctype);
    this.dialogRef.close();

    // TDB Implement Non TSVIDEO upload after api updates. <remove this condition>
    this.uploadMetadataForm = this.generateFormDataForDoctype(doctype);
    console.log("Upload metadata form:", this.uploadMetadataForm);
    // if (doctype !== 'tsvideo') {
    //   this.sharedDataService.updateShowUploadBanner(true);
    //   this.btlSharedLibraryService.updateStatusMessage(true, `${doctype.toUpperCase()} Upload Not Implemented`, false);
    //   return;
    // }
    // TDB Implement Non TSVIDEO upload after api updates.

    this.sharedDataService.updateUploadingState(true);
    this.sharedDataService.updateShowUploadBanner(true);
    this.sharedDataService.updateUploadingProgress(1);
    this.sharedDataService.updateProgressText("Uploading ...");

    /**allow parallel upload progress bar*/
    console.log("Initiate UPLOAD File AZ::", this.uploadMetadataForm)
    this.sharedDataService.updateUploadMetaData(this.uploadMetadataForm);
    let progressItem = new ProgressItem();
    progressItem.uploading = true;
    progressItem.progress = 1;
    progressItem.uploadMetaData = this.uploadMetadataForm;
    progressItem.progressText = "Uploading ...";
    this.progressItems.push(progressItem);
    console.log("Progress items:", this.progressItems)
    this.sharedDataService.updateProgressList(this.progressItems);

    console.log("FILE:", this.selectedFile)
    if (!this.selectedFile) {
      return;
    }
    if (this.selectedFile != null) {
      this.uploadBlockChunks();
    }
  }

  uploadBlockChunks() {
    if (this.totalBytesRemaining > 0) {
      const fileContent = this.selectedFile!.slice(
        this.currentFilePointer,
        this.currentFilePointer + this.maxBlockSize
      );
      const blockId = this.blockIdPrefix + pad(this.blockIds.length, 6);
      this.blockIds.push(btoa(blockId));
      console.log("######## UPLOAD BLOCK::", blockId)
      let latestBlockId = this.blockIds[this.blockIds.length - 1];
      this.videoService.uploadBlockChunk(this.uploadMetadataForm.contentKey, latestBlockId, this.fileName, fileContent)
        .subscribe({
          next: (response: HttpResponse<any>) => {
            if (response.status === 201) {
              this.bytesUploaded += fileContent.size;
              console.log("********* BYTES UPLOADED:", this.bytesUploaded, response.status);
              const percentComplete = ((this.bytesUploaded / this.selectedFile!.size) * 100).toFixed(2);
              this.sharedDataService.updateUploadingState(this.bytesUploaded <= this.selectedFile!.size);
              this.uploadProgress = percentComplete;
              this.sharedDataService.updateUploadingProgress(parseInt(this.uploadProgress));

              let currentUploadingState = this.bytesUploaded <= this.selectedFile!.size
              let currentProgress = parseInt(this.uploadProgress);
              this.progressItems = updateProgressItem(this.progressItems, this.uploadMetadataForm.contentKey, currentUploadingState, currentProgress, "Uploading ...")
              console.log("Progress items after first update:", this.progressItems)
              this.sharedDataService.updateProgressList(this.progressItems);

              this.currentFilePointer += this.maxBlockSize;
              this.totalBytesRemaining -= this.maxBlockSize;
              if (this.totalBytesRemaining < this.maxBlockSize) {
                this.maxBlockSize = this.totalBytesRemaining;
              }
              this.uploadBlockChunks();
            }
          },
          error: (error: HttpErrorResponse) => {
            console.error('Upload Error:', error);
            this.btlSharedLibraryService.updateStatusMessage(true, error.statusText, false);
            this.handleProgressItems();
            this.sharedDataService.updateUploadingState(false);
          }
        });
    } else {
      console.log("######## UPLOAD BLOCK FINISHED::", this.blockIds)
      this.commitBlockLists();
    }
  }

  commitBlockLists() {
    console.log("######## COMMIT BLOCK START::", this.blockIds)
    const blockListRequest = '<?xml version="1.0" encoding="utf-8"?><BlockList>' +
      this.blockIds.map(id => `<Latest>${id}</Latest>`).join('') +
      '</BlockList>';

    this.uploadMetadataForm = { ...this.uploadMetadataForm, blockList: blockListRequest }
    this.sharedDataService.updateProgressText("Completing Upload...");
    this.progressItems = updateProgressItem(this.progressItems, this.uploadMetadataForm.contentKey, true, parseInt(this.uploadProgress), "Completing Upload...")
    this.sharedDataService.updateProgressList(this.progressItems);
    this.videoService.commitBlockList(this.uploadMetadataForm)
      .subscribe({
        next: (response: HttpResponse<any>) => {
          console.log('Block list committed successfully:', response);
          console.log('Response Status:', response.status);
          console.log('Response Headers:', response.headers);
          this.completeFileUpload();
        },
        error: (error: HttpErrorResponse) => {
          console.error('Commit Block List Error:', error);
          console.log('Error Status:', error.statusText);
          console.log('Error Message:', error.message);
          console.log('Error Headers:', error.headers);
          this.btlSharedLibraryService.updateStatusMessage(true, error.statusText, false);
          this.handleProgressItems();
          this.sharedDataService.updateUploadingState(false);
        }
      });
  }

  completeFileUpload(){
    console.log("Complete Document Create/Upload ::");
    let metadata = this.getMetadataJson();
    const type = 'create';
    let fileName = this.uploadMetadataForm.fileName;
    // Check if selectedModel is a non-empty object and has a modelName property
    if (typeof this.selectedModel === 'object' && this.selectedModel.modelName) {
      metadata = {...metadata, 'model': this.selectedModel.modelName};
  }
    console.log("doctype:",this.selectedDocType)
    console.log("Metadata String::",metadata)

    this.videoService.completeFileUpload(this.uploadMetadataForm.contentKey,type,fileName,metadata,this.selectedDocType).subscribe({
      next:(response: HttpResponse<any>) => {
        console.log('File Upload Initiated using AZ Copy:', response);
        this.sharedDataService.updateUploadingState(false);
        this.btlSharedLibraryService.updateStatusMessage(true, `${this.fileName} uploaded to storage successfully`, true);
        //this.handleProgressItems();
        this.refreshArtifactList(this.uploadMetadataForm.contentKey);
      },
      error: (error: HttpErrorResponse) => {
        console.error('File Upload Initiated using AZ Copy', error);
        console.log('Error Status:', error.statusText);
        console.log('Error Message:', error.message);
        console.log('Error Headers:', error.headers);
        this.btlSharedLibraryService.updateStatusMessage(true, error.statusText, false);
        this.handleProgressItems();
        this.sharedDataService.updateUploadingState(false);
      }
    })
  }

  handleProgressItems() {
    this.progressItems = updateProgressItem(this.progressItems, this.uploadMetadataForm.contentKey, false, 0, "")
    this.sharedDataService.updateProgressList(this.progressItems);
    this.progressItems = removeProgressItem(this.progressItems, this.uploadMetadataForm.contentKey)
    this.sharedDataService.updateProgressList(this.progressItems);
  }

}
