<div *ngIf="showBanner">
    <lib-status-message></lib-status-message>
</div>
<div *ngIf="fileUploadForm">
    <form [formGroup]="fileUploadForm">
        <div style="margin-left: 20px;">
            <div [formGroup]="fileUploadForm">
                <div class="header-div">
                    <div class="header"><strong>Upload Source File</strong></div>
                    <div class="cross">
                        <span>
                            <mat-icon class="btn-icon" (click)="cancelUpload()">highlight_off</mat-icon>
                        </span>
                    </div>
                </div>
                <div class="upload-div">
                    <span class="mat-button-wrapper">
                        <button mat-raised-button class="upload-btn" [disabled]="disableUploadBtn" (click)="fileInput.click()">
                            <span class="mat-button-wrapper">
                                <mat-icon class="btn-icon">file_upload</mat-icon>
                                BROWSE
                            </span>
                        </button>
                        <input type="file" #fileInput style="display: none" (change)="handleFileSelect($event)">
                    </span>
                    <span *ngIf="selectedFile" class="selected-file-name">
                        {{ selectedFile.name }}
                    </span>
                </div>
                <div class="input-field-task input-field example-box" style="margin-top:12px">
                    <mat-form-field appearance="fill" class="form-input">
                        <mat-label class="input-label large-label">Title</mat-label>
                        <input matInput placeholder="Title" matInput formControlName="title">
                        <mat-error class="error" *ngIf="fileUploadForm.get('title')?.hasError('required')">
                            Title is required
                        </mat-error>
                        <mat-error class="error" *ngIf="fileUploadForm.get('title')?.hasError('pattern')">
                            Title is not valid
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="input-field-task input-field example-box">
                    <mat-form-field appearance="fill" class="form-input">
                        <mat-label class="input-label large-label">Description</mat-label>
                        <textarea matInput placeholder="Description" formControlName="description" class="custom-description-input" (input)="updateWordCount()"></textarea>
                        <mat-error class="error" *ngIf="fileUploadForm.get('description')?.hasError('required')">
                            Description is required
                        </mat-error>
                        <mat-error class="error" *ngIf="fileUploadForm.get('description')?.hasError('pattern')">
                            Description is not valid
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="word-count-container">
                    <p style="color: gray;font-size: small">
                        Word Count: {{ wordCount }}/{{ wordLimit }}
                        <span *ngIf="wordCount > wordLimit" style="color: red;"> (Exceeded)</span>
                    </p>
                </div>
            </div>
            <div id="output">
                <div mat-dialog-actions class="buttons">
                    <button class="btn-cancel mx-2" (click)="cancelUpload()">Cancel</button>
                    <button mat-raised-button [ngClass]="{'disable-button': !fileUploadForm.valid}" [disabled]="!fileUploadForm.valid" class="upload-btn"
                        (click)="uploadFileInBlocksNew()">
                        UPLOAD FILE
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>