 <!-- upload progress bar start-->
 <app-upload-progress></app-upload-progress>
<section class="outer-section responsive">
    <table mat-table [dataSource]="dataSource" class="actifacts-table" matSort matSortActive="filename"
        matSortDisableClear matSortDirection="desc">
        <ng-container matColumnDef="filename" sticky>
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear style="width: 630px;"> File </th>
            <td mat-cell *matCellDef="let element"  class="file-name" (click)="openVideoDialog(element)"> {{element.cmsObjectInfo.docName}} </td>
        </ng-container>

        <ng-container matColumnDef="group" sticky>
            <th mat-header-cell *matHeaderCellDef> Group </th>
            <td mat-cell *matCellDef="let element"> Flight</td>
        </ng-container>

        <ng-container matColumnDef="size">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Artifact ID</th>
            <td mat-cell *matCellDef="let element"> {{element.contentKey}} </td>
        </ng-container>

        <ng-container matColumnDef="createdDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Uploaded </th>
            <td mat-cell *matCellDef="let element"> {{formatDate(element.cmsObjectInfo.createdDate)}} </td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let video">
                <div class="d-flex actions-div">
                    <i class="material-symbols-outlined action-icon"   (click)="openMetadataDialog(video,'info')">info</i>
                    <ng-container *ngIf="!video.downloading && !video.paused">
                        <i class="material-symbols-outlined action-icon" (click)="downloadDocument(video)">download</i>
                    </ng-container>
                    <ng-container *ngIf="video.downloading && video.paused">
                        <i class="material-symbols-outlined action-icon" (click)="test(video)">replay</i>
                        <i class="material-symbols-outlined action-icon" (click)="test(video)">highlight_off</i>
                    </ng-container>
                    <ng-container  *ngIf="video.downloading && !video.paused">
                        <i class="material-symbols-outlined action-icon" (click)="test(video)">pause_circle</i>
                        <i class="material-symbols-outlined action-icon" (click)="test(video)">highlight_off</i>
                    </ng-container>
                    <button class="mat-menu-button" mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <app-download-video [video]="video" [downloadMetadataMap]="downloadMetadataMap" [downloading]="videoDownloadStates.get(video.contentKey) || false" (cancelDownload)="stopDownload($event)"></app-download-video>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item  (click)="openMetadataDialog(video,'edit')">Edit</button>
                        <button mat-menu-item (click)="handleDelete(video)">Delete</button>
                    </mat-menu>
                </div>

            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </table>
</section>

<mat-paginator
    *ngIf="dataLoad"
    (page)="pageChangeEvent($event)"
    [length]="pageLength"
    [pageSize]="pageSize"
    [showTotalPages]="3"
    style-paginator
    [pageSizeOptions]="[3, 5, 10, 20]">
</mat-paginator>
