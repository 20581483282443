export class DownloadProgress {
  percentDone: number = 0;
  loaded: string = "";
  total: string = "";
}


export class ProgressItem {
  uploading: boolean=false;
  progress: number = 1;
  uploadMetaData: any; 
  progressText: string="";
}