import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DownloadMetadata } from '../../models/download-meta-data.model';

@Component({
  selector: 'app-download-video',
  templateUrl: './download-video.component.html',
  styleUrls: ['./download-video.component.scss']
})
export class DownloadVideoComponent implements OnInit {

  @Input() downloadMetadataMap: Map<string, DownloadMetadata> | undefined; // Input property for download metadata map
  @Input() downloadMetadata?: DownloadMetadata; // Input property for download metadata
  @Input() downloading: boolean | undefined ;
  @Input() video: any | undefined; 
  @Output() cancelDownload = new EventEmitter<string>();
  constructor() { }

  ngOnInit(): void {
  }

  getTooltipText(): string {
    if (this.video.cmsObjectInfo.docObjectId && this.downloadMetadataMap) {
      const downloadMetadata = this.downloadMetadataMap.get(this.video.cmsObjectInfo.docObjectId);
      if (downloadMetadata && downloadMetadata.totalSize && downloadMetadata.remainingSize) {
        const totalSize = parseInt(downloadMetadata.totalSize);
        const remainingSize = parseInt(downloadMetadata.remainingSize);
        const downloadedSize = totalSize - remainingSize;
        const percentCompleted = Math.round((downloadedSize / totalSize) * 100);
        return `${downloadMetadata.progressText} ${percentCompleted}% ${downloadedSize}/${downloadMetadata.totalSize}`;
      }
    }
    return '';
  }

  onCancelDownload() {
    this.cancelDownload.emit(this.video);
  }

}
