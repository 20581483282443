<!--status message-->
<div *ngIf="showPlayerStatus">
  <lib-status-message></lib-status-message>
</div>
<div class="header-div" id="header-id" [style.margin-left.px]="marginValue">
    <div  style="font-size: 14px">
      Currently Playing:
      <br/>
      <span style="font-weight: bold">{{ video.metadata.title }}</span>
    </div> 
    <div class="cross">
        <span>
            <mat-icon class ="btn-icon" (click)="cancelPlayer()">highlight_off</mat-icon>
        </span>
    </div>
</div>
<div class="video-player-dialog">
  <div>
    <video
      id="video-player"
      class="video-js"
      controls
      preload="auto"
      width="640"
      height="360"
      data-setup="{}"
      [poster]="video.thumbnailStreamingUrl"
    >
      <!-- <source type="application/x-mpegURL"> -->
      <source [src]="video.streamingUrl" />
      <!-- Fallback for non-supported browsers -->
      <p class="vjs-no-js">
        To view this video, please enable JavaScript, and consider upgrading to
        a web browser that supports HTML5 video
      </p>
    </video>
    <div class="description-div">
        <div class="description-header">Description</div>
        <div>{{ videoMetaData["Description"] }}</div>
    </div>
  </div>
  <div class="video-metadata">
    <div class="mx-2">
      <div class="artifact-information">METADATA </div>
      <div class="metadata-box" style="max-height: 300px; overflow-y: auto;">
        <div *ngFor="let key of metadataOrder">
          <div class="metadata-entry" *ngIf="videoMetaData[key] && key!== 'Description'">
            <div class="metadata-header">{{ key }}</div>
            <div>{{ videoMetaData[key] }}</div>
          </div>
        </div>        
      </div>
    </div>
  </div>    
</div>
