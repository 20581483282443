import { Component, ElementRef, OnInit } from '@angular/core';
import { User } from './models/user.model';
import { UserService } from './services/user.service';
import { CanActivate, Router } from "@angular/router";
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { of, switchMap } from 'rxjs';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Boeing Training Library';
  user?: User;

  constructor(
    private myElement: ElementRef,
    private userService:UserService,
    private router: Router) {}

  ngOnInit(): void {
  this.getAuthorizedUsersDetails()
  }

  getAuthorizedUsersDetails(): void {
    this.userService.isLoggedIn().pipe(
      switchMap((isAuthorized: boolean) => {
        console.log("isAuthorized:", isAuthorized);
        if (isAuthorized) {
            return this.userService.getUserDetails();
        } else {
          //this.router.navigate(["unauthorized"]);
          return of(null); 
        }
      })
    ).subscribe({
      next: (response: any) => {
        if (response) {
          console.log("Response From Users API:", response);
          this.user = response.data;
          console.log("user:", this.user);
        } else {
          console.log("User not authorized");
        }
      },
      error: (error: HttpErrorResponse) => {
        console.error("Error getting user details:", error);
      }
    });
  }

  logout(): void {
  }

  toggleMenu(): void {
    this.myElement.nativeElement.closest('body').classList.toggle('menu-opened');
   }

   onResize(evt:any) {
     this.myElement.nativeElement.closest('body').classList.remove('menu-opened');
   }
}
