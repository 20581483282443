import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BtlSharedLibraryService } from 'btl-shared-library';
import { Router } from 'express';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { SpinnerOverlayService } from 'src/app/services/spinner-overlay-service';
import { VideoService } from 'src/app/services/video-service';
import { FormatControlNamePipe } from 'src/app/pipe/format-control-name.pipe';
import { calculateMaxBlockSize, computeNewMajorVersion,pad,removeProgressItem,sortVideosByCreatedDate, updateProgressItem } from 'src/app/utils/video-utils';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ProgressItem } from 'src/app/models/access.model';
import { DocGroup, Models } from 'src/app/models/doc-group.model';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';

interface PropertyDefinition {
  propertyName: string;
  propertyType: string;
  repeat: string;
  restriction: string;
}

@Component({
  selector: 'app-metadata',
  templateUrl: './metadata.component.html',
  styleUrls: ['./metadata.component.scss'],
  providers: [FormatControlNamePipe]
})
export class MetadataComponent implements OnInit {
  propertyForm!: FormGroup;
  initialFormValues: any;
  propertyDefinitions: any;
  metadata: any;
  popupData : any;
  fileName : any;
  artifactId : any;
  editMode:any;
  videos: any[] = [];
  latestCreatedVideos: boolean = true;
  dataSource: any;
  pageSize = 7;
  showPages = 2;
  pageLength = this.pageSize * (this.showPages - 1);
  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;
  dataLoad = false;

  maxBlockSize: number = 5 * 1024 * 1024;
  numberOfBlocks: number = 1;
  selectedFile: File | null = null;
  currentFilePointer: number = 0;
  totalBytesRemaining: number = 0;
  blockIds: string[] = [];
  blockIdPrefix: string = 'block-';
  uploadMetadataForm: any;
  progressItems: ProgressItem[] = []
  bytesUploaded: number = 0;
  uploadProgress: string = '0.00';
  selectedDocType:string = "";
  newContentId : string = "";
  disableUploadBtn: boolean = false;
  isUploadVideo: boolean = false;
  selectedThumbnailFile: File | null = null;
  thumbnailFileName: any = "";
  isUploadThumbnailImage: boolean = false;
  renditions: any;
  IMAGE_TYPES = ['jpg','jpeg','png','PNG','gif','bmp','tiff','tif','webp','svg','ico','heif','heic','raw'];
  TITLE_REGEX = /^[A-Za-z0-9][A-Za-z0-9,!@#$%^&*()_+{}\[\]:;'"<>,.?/~` -]*$/;
  VIDEO_TYPES = ['zip','flv', 'mxf', 'gxf', 'ps', '3gp', '3gpp', 'mpg', 'wmv', 'asf', 'avi', 'mp4', 'm4a', 'm4v', 'isma', 'ismv', 'dvr-ms', 'mkv', 'wav', 'mov'];
  showBanner: boolean = false;
  isFileValid: boolean = true;
  isErrorState(control: AbstractControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.invalid && (control.dirty || control.touched));
  }
  selectedModel: string | { modelName: string } = "";
  models!: Models[];

  constructor(public dialog: MatDialog,
    private sharedDataService: SharedDataService,
    private videoService: VideoService,
    private spinnerOverlayService: SpinnerOverlayService,
    private btlSharedLibraryService: BtlSharedLibraryService,
    public dialogRef: MatDialogRef<MetadataComponent>,
    private formatControlNamePipe: FormatControlNamePipe,
    
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.popupData  = this.data
      this.editMode = this.popupData.type === 'edit' ? true : false;
      this.propertyForm = new FormGroup({});
      this.models = this.videoService.getStaticModels();
    }

  ngOnInit(): void {
    this.selectedDocType = this.popupData.docType;
    this.loadData(); 
    this.loadPropertyDefinitions();
  }

  loadPropertyDefinitions(): void {
    this.videoService.getPropertyDefinitions(this.popupData.docType).subscribe((response: any) => {
      this.propertyDefinitions = response.doctypes[0].definition;
      this.createForm();
      this.loadData();
    });
  }


  createForm(): void {
    this.propertyDefinitions.forEach((property: any) => {
      const control = new FormControl('', property.restriction === 'Mandatory' ? Validators.required : null);
      this.propertyForm.addControl(property.propertyName, control);
    });
  }

  populateForm(): void {
    Object.keys(this.propertyForm.controls).forEach((propertyName: string) => {
      if (this.metadata[propertyName]) {
        this.propertyForm.controls[propertyName].setValue(this.metadata[propertyName]);
      }
    });
    this.storeInitialFormValues();
  }

  storeInitialFormValues(): void {
    this.initialFormValues = this.propertyForm.getRawValue();
  }

  loadData() : void{
    this.spinnerOverlayService.show();
      console.log("this.popupData.docType:",this.popupData.docType)
      this.videoService.fetchMetaForADocument(this.popupData.docType,this.popupData.documentid).subscribe((data:any) => {
      this.metadata = data.data[0].metadata;
      this.populateForm();
      this.fileName = data.data[0].cmsObjectInfo.docName;
      console.log("last uploaded videoFile : ", this.fileName);
      this.artifactId = data.data[0].contentKey;
      this.videoService.fetchRenditions(this.selectedDocType, this.artifactId)
      .subscribe(
        (response: any) => {
          const renditions = response.data;
          const thumbnailRendition = renditions.find((rendition: any) => rendition.renditionType === 'thumbnail');
          if (thumbnailRendition && thumbnailRendition.streamingInfo && thumbnailRendition.streamingInfo.length > 0) {
            this.thumbnailFileName = thumbnailRendition.streamingInfo[0].fileName;
            console.log("last loaded thumbnailFileName : " , this.thumbnailFileName);
          } else {
            console.error('Thumbnail rendition not found or streaming info missing.');
          }
        },
        (error: any) => {
          console.error('Error fetching renditions:', error);
        }
      );
      this.spinnerOverlayService.hide();
    },
    (error) => {
        console.error('Error fetching data:', error);
        this.spinnerOverlayService.hide();
    })
  }

  close() : void{
    this.dialogRef.close();
  }

  handleEditSubmit(){
    this.newContentId = computeNewMajorVersion(this.artifactId);
    const currentFormValues = this.propertyForm.getRawValue();
    let hasFormValueChanged = JSON.stringify(this.initialFormValues) !== JSON.stringify(currentFormValues);
    if(this.selectedFile){
      this.uploadFileInBlocksNew(this.selectedDocType);
    }
    if(hasFormValueChanged){
      this.updateOnlyMetadata();
    }
    // call update rendition method if Thumbnail is updated
    if(this.selectedThumbnailFile){
      this.updateThumbnailRendition();
    }
  }
  updateThumbnailRendition(){
    // fetch thumbnail initially
    this.spinnerOverlayService.show();
    this.videoService.fetchRenditions(this.selectedDocType, this.artifactId)
      .subscribe(response => {
        console.log('Renditions fetched successfully:', response);
        this.renditions = response.data;
        console.log("renditions : ", this.renditions);

        // Filter to get all renditionIds with renditionType 'thumbnail'
      const thumbnailRenditions = this.renditions.filter((rendition: any) => rendition.renditionType === 'thumbnail');
      const thumbnailRenditionIds = thumbnailRenditions.flatMap((rendition: any) => 
        rendition.streamingInfo.map((info: any) => info.renditionId)
      );
      console.log("Thumbnail Rendition IDs: ", thumbnailRenditionIds);

        // Call updateRenditionOnCMS after fetching renditions
        if (thumbnailRenditionIds.length > 0) {
          console.log("Calling Update rendition method ");
          // Call updateRenditionOnCMS method for each thumbnail rendition if the thumbnail exists
          thumbnailRenditionIds.forEach((renditionId: string) => {
            if (this.selectedThumbnailFile) {
              this.videoService.updateRenditionOnCMS(this.selectedDocType, renditionId, this.artifactId, true, 'thumbnail', this.selectedThumbnailFile)
                .subscribe(
                  response => {
                    this.dialogRef.close(response);
                    this.spinnerOverlayService.hide();
                    this.sharedDataService.updateShowUploadBanner(true);
                    this.btlSharedLibraryService.updateStatusMessage(true, `Thumbnail updated successfully`, true);
                    console.log('Thumbnail updated successfully for renditionId:', renditionId);
                  },
                  error => {
                    console.error('Error updating rendition:', error);
                    this.spinnerOverlayService.hide();
                  }
                );
            }
          });
        } else {
          console.log('No thumbnail renditions found');
          this.spinnerOverlayService.hide();
        }

      }, error => {
        // create rendition if it does not exists
        console.error('Error fetching renditions: CREATE RENDITION');
        if (this.selectedThumbnailFile) {
          this.dialogRef.close("close");
          this.sharedDataService.updateShowUploadBanner(true);
          this.videoService.createRenditionOnCMS(this.selectedDocType, 'thumbnail', this.artifactId, this.selectedThumbnailFile)
          .subscribe(
          response => {
            this.dialogRef.close(response);
            this.btlSharedLibraryService.updateStatusMessage(true, `Thumbnail uploaded successfully`, true);
            console.log('Rendition created successfully:', response);
          },
          error => {
            console.error('Error creating rendition:', error);
          }
        );
       }
      });

    }

  updateOnlyMetadata(){
    let metadata = this.getMetadataJson();
    this.spinnerOverlayService.show();
    this.videoService.updateArtifactMetadata(this.newContentId, metadata, this.selectedDocType).subscribe(response => {
      this.dialogRef.close(response);
      console.log("update metadata response:",response);
      this.spinnerOverlayService.hide();
      this.sharedDataService.updateShowUploadBanner(true);
      this.btlSharedLibraryService.updateStatusMessage(true, `${this.fileName} Edited successfully`, true);
	    // navigate to other media type
      setTimeout(()=>{this.navigateToIndex()},1000)
    }, error => {
      console.error('Error updating metadata:', error);
      this.spinnerOverlayService.hide();
    });
  }

  navigateToIndex(){
    if(this.selectedDocType === 'TSVIDEO'){
      this.sharedDataService.updateTabIndex(0);
    }else{
      this.sharedDataService.updateTabIndex(1);
    }
  }

  getAllVideos(): void {
    this.spinnerOverlayService.show()
    this.videoService.getAllVideosFromCms(this.selectedDocType).subscribe({
      next: (response) => {
        this.videos = response.data.data;
        this.toggleDateOrder();
        this.spinnerOverlayService.hide();
      },
      error: (error) => {
        console.error('Error fetching videos:', error);
        this.spinnerOverlayService.hide()
      }
    });
  }

  toggleDateOrder() {
    this.latestCreatedVideos = !this.latestCreatedVideos;
    this.videos = sortVideosByCreatedDate(this.videos, this.latestCreatedVideos)
    this.sharedDataService.updateVideosList(this.videos);
  }

  getUploadMetadataForm(){
    const formData: { [key: string]: any } = {
      contentKey: this.newContentId,
      fileName: this.selectedFile?.name,
      fileType: this.selectedFile?.type
    };

    this.propertyDefinitions.forEach((def: PropertyDefinition) => {
      formData[def.propertyName] = this.propertyForm?.get(def.propertyName)?.value;
    });
    return formData;
  }

  getMetadataJson(){
    const metadata: { [key: string]: any } = {};
    this.propertyDefinitions.forEach((def: PropertyDefinition) => {
      metadata[def.propertyName] = this.propertyForm?.get(def.propertyName)?.value == null ? "" : this.propertyForm?.get(def.propertyName)?.value;
    });
    return metadata;
  }
  
  cancelUpload() {
    this.dialogRef.close();
  }

  
  handleFileSelect(event: any): void {
    const files = event.target.files;
    this.selectedFile = files[0];

    if (this.selectedFile != null) {
      this.totalBytesRemaining = this.selectedFile.size;
      this.fileName = this.selectedFile.name;
      this.maxBlockSize = calculateMaxBlockSize(this.selectedFile.size);
    }
    this.validateFileExtention(event)

    // Update the file control with the selected file's name
    this.propertyForm?.patchValue({
      file: this.selectedFile?.name
    });
  }

  handleThumbnailFileUpload(event: any): void {
    const files = event.target.files;
    this.selectedThumbnailFile = files[0];
    if (this.selectedThumbnailFile != null) {
      this.thumbnailFileName = this.selectedThumbnailFile.name;
    }

    this.validateThumbnailFileExtention(event);
  }

  validateThumbnailFileExtention(event: any) {
    console.log("inside validation with file name : ", this.thumbnailFileName);
    this.isUploadThumbnailImage = this.IMAGE_TYPES.includes(this.thumbnailFileName.split('.').pop());
    if (!this.isUploadThumbnailImage) {
      console.log("Files:pre:", event.target.files)
      event.target.value = null;
      this.selectedThumbnailFile = null;
      console.log("Files:", event.target.files)
      this.showBanner = true;
      this.sharedDataService.updateShowUploadBanner(false);
      this.btlSharedLibraryService.updateStatusMessage(true, "Invalid file extension. Allowed extensions for images are jpeg, jpg, png, gif, tiff, bmp, webp, svg, ico, psd, raw", false);
    }

    setTimeout(() => {
      this.showBanner = false;
    }, 4000)
  }

  validateFileExtention(event: any) {
    if (this.selectedDocType === 'mtspkg') {
      this.VIDEO_TYPES.push('zip');
    }
    this.isUploadVideo = this.VIDEO_TYPES.includes(this.fileName.split('.').pop());
    this.isFileValid=this.isUploadVideo;
    if (!this.isUploadVideo) {
      console.log("Files:pre:", event.target.files)
      event.target.value = null;
      this.selectedFile = null;
      console.log("Files:", event.target.files)
      this.showBanner = true;
      this.sharedDataService.updateShowUploadBanner(false);
      this.btlSharedLibraryService.updateStatusMessage(true, "Invalid file extension. Allowed extensions are mp4, wav, mov, avi, flv, mpeg, mkv", false);
    }
    setTimeout(() => {
      this.showBanner = false;
    }, 4000)
  }

  /**=========================================== Edit Upload in Chunks Functionality ==================================================== */

  uploadFileInBlocksNew(doctype: string): void {
    console.log("Uplaod File for:", doctype);
    this.dialogRef.close();

    // TDB Implement Non TSVIDEO upload after api updates. <remove this condition>
    this.uploadMetadataForm = this.getUploadMetadataForm();
    console.log("Upload metadata form:", this.uploadMetadataForm);

    this.sharedDataService.updateUploadingState(true);
    this.sharedDataService.updateShowUploadBanner(true);
    this.sharedDataService.updateUploadingProgress(1);
    this.sharedDataService.updateProgressText("Uploading ...");

    /**allow parallel upload progress bar*/
    console.log("Initiate UPLOAD File AZ::", this.uploadMetadataForm)
    this.sharedDataService.updateUploadMetaData(this.uploadMetadataForm);
    let progressItem = new ProgressItem();
    progressItem.uploading = true;
    progressItem.progress = 1;
    progressItem.uploadMetaData = this.uploadMetadataForm;
    progressItem.progressText = "Uploading ...";
    this.progressItems.push(progressItem);
    console.log("Progress items:", this.progressItems)
    this.sharedDataService.updateProgressList(this.progressItems);

    console.log("FILE:", this.selectedFile)
    if (!this.selectedFile) {
      return;
    }
    if (this.selectedFile != null) {
      this.uploadBlockChunks();
    }
  }

  uploadBlockChunks() {
    if (this.totalBytesRemaining > 0) {
      const fileContent = this.selectedFile!.slice(
        this.currentFilePointer,
        this.currentFilePointer + this.maxBlockSize
      );
      const blockId = this.blockIdPrefix + pad(this.blockIds.length, 6);
      this.blockIds.push(btoa(blockId));
      console.log("######## UPLOAD BLOCK::", blockId)
      let latestBlockId = this.blockIds[this.blockIds.length - 1];
      this.videoService.uploadBlockChunk(this.uploadMetadataForm.contentKey, latestBlockId, this.fileName, fileContent)
        .subscribe({
          next: (response: HttpResponse<any>) => {
            if (response.status === 201) {
              this.bytesUploaded += fileContent.size;
              console.log("********* BYTES UPLOADED:", this.bytesUploaded, response.status);
              const percentComplete = ((this.bytesUploaded / this.selectedFile!.size) * 100).toFixed(2);
              this.sharedDataService.updateUploadingState(this.bytesUploaded <= this.selectedFile!.size);
              this.uploadProgress = percentComplete;
              this.sharedDataService.updateUploadingProgress(parseInt(this.uploadProgress));

              let currentUploadingState = this.bytesUploaded <= this.selectedFile!.size
              let currentProgress = parseInt(this.uploadProgress);
              this.progressItems = updateProgressItem(this.progressItems, this.uploadMetadataForm.contentKey, currentUploadingState, currentProgress, "Uploading ...")
              console.log("Progress items after first update:", this.progressItems)
              this.sharedDataService.updateProgressList(this.progressItems);

              this.currentFilePointer += this.maxBlockSize;
              this.totalBytesRemaining -= this.maxBlockSize;
              if (this.totalBytesRemaining < this.maxBlockSize) {
                this.maxBlockSize = this.totalBytesRemaining;
              }
              this.uploadBlockChunks();
            }
          },
          error: (error: HttpErrorResponse) => {
            console.error('Upload Error:', error);
            this.btlSharedLibraryService.updateStatusMessage(true, error.statusText, false);
            this.handleProgressItems();
            this.sharedDataService.updateUploadingState(false);
          }
        });
    } else {
      console.log("######## UPLOAD BLOCK FINISHED::", this.blockIds)
      this.commitBlockLists();
    }
  }

  commitBlockLists() {
    console.log("######## COMMIT BLOCK START::", this.blockIds)
    const blockListRequest = '<?xml version="1.0" encoding="utf-8"?><BlockList>' +
      this.blockIds.map(id => `<Latest>${id}</Latest>`).join('') +
      '</BlockList>';

    this.uploadMetadataForm = { ...this.uploadMetadataForm, blockList: blockListRequest }
    this.sharedDataService.updateProgressText("Completing Upload...");
    this.progressItems = updateProgressItem(this.progressItems, this.uploadMetadataForm.contentKey, true, parseInt(this.uploadProgress), "Completing Upload...")
    this.sharedDataService.updateProgressList(this.progressItems);
    this.videoService.commitBlockList(this.uploadMetadataForm)
      .subscribe({
        next: (response: HttpResponse<any>) => {
          console.log('Block list committed successfully:', response);
          console.log('Response Status:', response.status);
          console.log('Response Headers:', response.headers);
          this.completeFileUpload();
        },
        error: (error: HttpErrorResponse) => {
          console.error('Commit Block List Error:', error);
          console.log('Error Status:', error.statusText);
          console.log('Error Message:', error.message);
          console.log('Error Headers:', error.headers);
          this.btlSharedLibraryService.updateStatusMessage(true, error.statusText, false);
          this.handleProgressItems();
          this.sharedDataService.updateUploadingState(false);
        }
      });
  }

  completeFileUpload(){
    console.log("Complete Document Create/Upload ::");
    let metadata = this.getMetadataJson();
    const type = 'update';
    let fileName = this.uploadMetadataForm.fileName;
    
    console.log("doctype:",this.selectedDocType)
    console.log("Metadata String::",metadata)

    this.videoService.completeFileUpload(this.uploadMetadataForm.contentKey,type,fileName,metadata,this.selectedDocType).subscribe({
      next:(response: HttpResponse<any>) => {
        console.log('File Upload Initiated using AZ Copy:', response);
        this.sharedDataService.updateUploadingState(false);
        this.btlSharedLibraryService.updateStatusMessage(true, `${this.fileName} Edited successfully`, true);
        this.handleProgressItems();
        // reload updated video list
        setTimeout(()=>{this.navigateToIndex()},1000)
      },
      error: (error: HttpErrorResponse) => {
        console.error('File Upload Initiated using AZ Copy', error);
        console.log('Error Status:', error.statusText);
        console.log('Error Message:', error.message);
        console.log('Error Headers:', error.headers);
        this.btlSharedLibraryService.updateStatusMessage(true, error.statusText, false);
        this.handleProgressItems();
        this.sharedDataService.updateUploadingState(false);
      }
    })
  }

  handleProgressItems() {
    this.progressItems = updateProgressItem(this.progressItems, this.uploadMetadataForm.contentKey, false, 0, "")
    this.sharedDataService.updateProgressList(this.progressItems);
    this.progressItems = removeProgressItem(this.progressItems, this.uploadMetadataForm.contentKey)
    this.sharedDataService.updateProgressList(this.progressItems);
  }

  hasFormChanged(): boolean {
    const currentFormValues = this.propertyForm.getRawValue();
    let hasFormValueChanged = JSON.stringify(this.initialFormValues) !== JSON.stringify(currentFormValues);
    return this.isUploadVideo || hasFormValueChanged || this.thumbnailFileName;
  }

}