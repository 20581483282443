<div class="d-flex">
    <mat-card class="video-card m-3" *ngFor="let item of cardDetails;">
        <!-- <img mat-card-image src="https://image.cnbcfm.com/api/v1/image/106355393-15799832377352020-01-25t192808z_951640316_rc27ne9v56ms_rtrmadp_3_boeing-777x.jpg?v=1679574522&w=1600&h=900" alt="Photo of a Shiba Inu"> -->
        <img mat-card-image src="../../../assets/Boeing-Logo.png">
        <mat-card-content>
            <div class="title-div">737 TRAINING</div>
            <div class="description-div">Boeing {{item}} Fleet Training and documentation</div>
        </mat-card-content>
        <mat-card-actions>
            <button mat-button class="watch-btn">
                <span class="material-symbols-outlined">
                    arrow_forward
                </span>
                WATCH</button>
        </mat-card-actions>
    </mat-card>
</div>