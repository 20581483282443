import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { delay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from '../models/user.model';
import { Models } from '../models/doc-group.model';

@Injectable({
  providedIn: 'root',
})
export class VideoService {
  private httpHeaders = new HttpHeaders()
    .set('Ocp-Apim-Subscription-Key', environment.btlVsSubscriptionKey);


  constructor(
    private http: HttpClient) { }

  getStaticModels(): Models[] {
    return [
      {
        "modelName": "Non-Model Specific"
      },
      {
        "modelName": "707"
      },
      {
        "modelName": "717-200"
      },
      {
        "modelName": "720"
      },
      {
        "modelName": "727"
      },
      {
        "modelName": "737"
      },
      {
        "modelName": "737 Classic"
      },
      {
        "modelName": "737 MAX"
      },
      {
        "modelName": "737 NG"
      },
      {
        "modelName": "737 Original"
      },
      {
        "modelName": "747"
      },
      {
        "modelName": "747-8"
      },
      {
        "modelName": "757"
      },
      {
        "modelName": "767"
      },
      {
        "modelName": "777"
      },
      {
        "modelName": "777X"
      },
      {
        "modelName": "787"
      }
    ]
  }

  getAllVideos(): Observable<any> {
    return this.http.get<any>(`${environment.backendBaseUrl}/wrapper/videos`, {
      headers: this.httpHeaders,
    });
  }


  uploadVideoChunk(chunk: Blob, uploadMetadataForm: any, currentChunk: number, totalChunks: number): Observable<any> {
    console.log("headers:", this.httpHeaders)

    let formData = new FormData();
    formData.append('file', chunk);
    const metadataParam = encodeURIComponent(JSON.stringify(uploadMetadataForm));

    return this.http.post<any>(
      `${environment.backendBaseUrl}/cms-upload/${uploadMetadataForm.contentKeyId}/cms/${currentChunk}/${totalChunks}?metadata=${metadataParam}`,
      formData,
      { headers: this.httpHeaders }
    );
  }


  initiateVideoUpload(uploadMetadataForm: any): Observable<any> {
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json')
    headers.set('Ocp-Apim-Subscription-Key', environment.backendCmsVmSubscriptionKey);
    console.log("headers:", headers)
    const videoContentKeyId = this.generateRandomUUID('TSVIDEO');
    return this.http.post<any>(`${environment.backendBaseUrl}/initiate/cms-upload/${videoContentKeyId}/cms`, uploadMetadataForm, { headers: this.httpHeaders });
  }

  sendToCloud(contentId: any, docType:any, uploadedDate:any, userId:any): Observable<any> {
    return this.http.post<any>(`${environment.backendBaseUrl}/artifact/cms/movetocloud/?contentId=${contentId}&docType=${docType}&uploadedDate=${uploadedDate}&userId=${userId}`,  {}, { headers: this.httpHeaders });
  }
  
  getCmsSassStreamUrl(videoContentKeyId: string) {
    return this.http.get<any>(`${environment.backendBaseUrl}/cms-stream/?contentId=${videoContentKeyId}`, {
      headers: this.httpHeaders,
    });
  }

  generateRandomUUID(doctype:string) {
    const prefix =`TS_${doctype.toUpperCase()}`;
    const suffix = ":1.0";
    const randomString = Math.random().toString(36).substring(2, 10).toUpperCase();
    const uuid = `${prefix}_${randomString}${suffix}`;
    return uuid;
  }

  initiateVideoUploadAz(uploadMetadataForm: any): Observable<any> {
    // deprecated
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json')
    headers.set('Ocp-Apim-Subscription-Key', environment.btlVsSubscriptionKey);
    console.log("headers:", headers)
    const videoContentKeyId = this.generateRandomUUID('TSVIDEO');
    return this.http.post<any>(`${environment.backendBaseUrl}/initiate/cms-azcopy/?contentId=${videoContentKeyId}`, uploadMetadataForm, { headers: this.httpHeaders });
  }

  completeVideoUploadAz(uploadMetadataForm: any): Observable<any> {
    // deprecated
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json')
    headers.set('Ocp-Apim-Subscription-Key', environment.btlVsSubscriptionKey);
    console.log("headers:", headers)
    const videoContentKeyId = this.generateRandomUUID('TSVIDEO');
    return this.http.post<any>(`${environment.backendBaseUrl}/complete/cms-azcopy/?contentId=${videoContentKeyId}`, uploadMetadataForm, { headers: this.httpHeaders });
    
  }

  getAllVideosFromCms(doctype:string) {
    //let doctype = "TSVIDEO";
    const searchCriteria = { "doctype": doctype }
    const metadataParam = encodeURIComponent(JSON.stringify(searchCriteria));
    const order = "ASCENDING";
    const page = 1
    const perPage = 1000
    return this.http.get<any>(`${environment.backendBaseUrl}/cms/${doctype}/metadata/${metadataParam}?order=${order}&page=${page}&perPage=${perPage}`, {
      headers: this.httpHeaders,
    });
  }

  fetchMetaForADocument(docType: string, contentId: string) {
    return this.http.get<any>(`${environment.backendBaseUrl}/cms/metadata/doctype/${docType}?contentId=${contentId}`, {
      headers: this.httpHeaders,
    });
  }

  getPropertyDefinitions(docTypeName: string): Observable<any> {
    let httpHeaders = new HttpHeaders()
      .set('Ocp-Apim-Subscription-Key', environment.backendCmsVmSubscriptionKey);
    return this.http.get<any>(`${environment.backendCmsVmBaseUrl}/lookup/doctypes?docTypeName=${docTypeName}`, {
      headers: httpHeaders,
    });
  }

  getMetadataBySearchCriteria(docType: string, contentKey: string): Observable<any> {
    let httpHeaders = new HttpHeaders()
      .set('Ocp-Apim-Subscription-Key', environment.backendCmsVmSubscriptionKey);
    return this.http.get<any>(`${environment.backendCmsVmBaseUrl}/documents/doctype/${docType}/metadata/${encodeURIComponent(contentKey)}?order=ASCENDING&page=1&perPage=1000`, {
      headers: httpHeaders,
    });
  }

  downloadArtifact(docType: string, contentId: string): Observable<HttpEvent<any>> {
    return this.http.get(`${environment.backendBaseUrl}/cms/download/artifact/doctype/${docType}?contentId=${contentId}`, {
      reportProgress: true,
      observe: 'events',
      responseType: 'blob',
      headers: this.httpHeaders,
    });
  }

  uploadBlockChunk(contentId: string, blockId: string, fileName: string, block: any) {
    let blockData = new FormData();
    blockData.append('block', block);
    const uplaodBlockChunkUrl = `${environment.backendBaseUrl}/artifact/upload/block/?contentId=${contentId}&blockId=${blockId}&fileName=${fileName}`
    return this.http.put<any>(
      uplaodBlockChunkUrl,
      blockData, {
      headers: this.httpHeaders,
      observe: 'response'
    }
    );
  }

  commitBlockList(uploadMetadataForm: any) {
    const uplaodBlockChunkUrl = `${environment.backendBaseUrl}/artifact/complete/upload/?contentId=${uploadMetadataForm.contentKey}`
    return this.http.put<any>(
      uplaodBlockChunkUrl, uploadMetadataForm, {
      headers: this.httpHeaders,
      observe: 'response'
    }
    );
  }

  completeFileUpload(contentId:string, type:string, fileName:string, metadata:any, docType:string){
    const encodedMetadataString = encodeURIComponent(JSON.stringify(metadata));
    const completeFileUploadUrl = `${environment.backendBaseUrl}/artifact/cms/upload/?contentId=${contentId}&type=${type}&fileName=${fileName}&metadata=${encodedMetadataString}&docType=${docType}`
    return this.http.post<any>(
      completeFileUploadUrl,
      {},
      {
        headers: this.httpHeaders,
        observe: 'response'
      }
    );
  }

  getMetadataByDocTypeName(docType: string): Observable<any> {
    let httpHeaders = new HttpHeaders()
      .set('Ocp-Apim-Subscription-Key', environment.backendCMSBaseSubscriptionKey);
    return this.http.get<any>(`${environment.backendCMSBaseURL}/lookup/doctypes?docTypeName=${docType}`, {
      headers: httpHeaders,
    });
  }

  deleteVideo(docType: any,contentKey: any){
    console.log('inside delete')
    const contentKeyWithoutVersion = contentKey.substring(0,contentKey.lastIndexOf(":"))
    const url = `${environment.backendBaseUrl}/cms/delete/artifact/doctype/${docType}?contentId=${contentKeyWithoutVersion}`
    return this.http.delete(url , {headers:this.httpHeaders})
  }

  getAllRenditions(contentId:string){
    const url = `${environment.backendBaseUrl}/renditions?contentId=${contentId}`
    return this.http.get(url , {headers:this.httpHeaders})
  }

  streamRenditionUsingRange(type:string,canonicalPath:string){
    let httpHeaders = new HttpHeaders()
    .set('Ocp-Apim-Subscription-Key', environment.btlVsSubscriptionKey)
    .set('Accept','video/mp4;charset=UTF-8')
    return this.http.get(`${environment.backendBaseUrl}/stream/rendition/range?type=${type}&canonicalPath=${canonicalPath}`, {
      reportProgress: true,
      responseType: 'blob',
      headers: this.httpHeaders,
    });
  }

  streamRenditionUsingHttpClient(type:string,canonicalPath:string){
    return this.http.get(`${environment.backendBaseUrl}/stream/rendition/http?type=${type}&canonicalPath=${canonicalPath}`, {
      reportProgress: true,
      responseType: 'blob',
      headers: this.httpHeaders,
    });
  }

  streamRenditionUsingOptimizedHttpClient(type:string,canonicalPath:string){
    return this.http.get(`${environment.backendBaseUrl}/stream/rendition/reactive?type=${type}&canonicalPath=${canonicalPath}`, {
      reportProgress: true,
      responseType: 'blob',
      headers: this.httpHeaders,
    });
  }

  async streamVideoUsingFetch(type:string,canonicalPath:string): Promise<ReadableStream<Uint8Array>> {
    let url = `${environment.backendBaseUrl}/stream/rendition/range?type=${type}&canonicalPath=${canonicalPath}`;
    try {
      const headers = new Headers({
        'Ocp-Apim-Subscription-Key':environment.btlVsSubscriptionKey,  
        'Accept':'video/mp4;charset=UTF-8'
      });
      const response = await fetch(url, {
        method: 'GET',
        headers: headers,
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.body as ReadableStream<Uint8Array>;
    } catch (error) {
      console.error('Fetch error:', error);
      throw error;
    }
  }

  editArtifactMetadata(contentId: string,title: string, description: string): Observable<any> {
    //deprecated , will now use updateArtifactMetadata
    const params = new HttpParams()
      .set('contentId', contentId)
      .set('title', title)
      .set('description', description);
    
    return this.http.put<any>(`${environment.backendBaseUrl}/cms/update/artifact/metadata`,null,{ headers: this.httpHeaders, params });
  }

  updateArtifactMetadata(contentId:string,metadata:any,docType:string): Observable<any> {
    const encodedMetadataString = encodeURIComponent(JSON.stringify(metadata));
    return this.http.put<any>(`${environment.backendBaseUrl}/cms/update/artifact/metadata?contentId=${contentId}&metadata=${encodedMetadataString}&docType=${docType}`,null,{ 
      headers: this.httpHeaders
    });
  }

  createRenditionOnCMS(doctype: string, renditiontype: string, parentcontentkeyid: string, file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);
  
    const url = `${environment.backendBaseUrl}/cms/rendition/create?doctype=${doctype}&renditionType=${renditiontype}&contentkey=${parentcontentkeyid}`;
    console.log("rendition url : ", url);
    return this.http.post<any>(url, formData, { headers: this.httpHeaders });
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    return throwError('Something bad happened; please try again later.');
  }

  updateRenditionOnCMS(doctype: string, renditionid: string, parentdocumentid: string, major: boolean, renditiontype: string, file?: File): Observable<any> {
    const url = `${environment.backendBaseUrl}/cms/rendition/update?doctype=${doctype}&renditionid=${renditionid}&parentdocumentid=${parentdocumentid}&MajorVersion=${major}&renditiontype=${renditiontype}`;
    console.log("update rendition url : ", url);

    const formData: FormData = new FormData();
    if (file) {
        formData.append('file', file, file.name);
    }

    return this.http.post<any>(url, formData, { headers: this.httpHeaders });
  }


  fetchRenditions(doctype: string, parentdocumentid: string): Observable<any> {
    const url = `${environment.backendBaseUrl}/cms/renditions?doctype=${doctype}&contentkey=${parentdocumentid}`;
    return this.http.get<any>(url, { headers: this.httpHeaders });
  }
}
