<div class="full-height" (window:resize)="onResize($event)">
  <nav class="navbar navbar-dark bg-dark">
    <div class="menu-btn" (click)="toggleMenu()"><span></span> <span></span> <span></span></div>
    <a href="#" class="navbar-brand">Boeing Training Library</a>

    <ul class="main-nav navbar-nav d-flex flex-row ml-auto nav-custom">
      <li class="nav-item nav-link text-white">
        <i class="material-icons">apps</i>
      </li>
      <li class="nav-item nav-link text-white">
        <i class="material-icons" [matMenuTriggerFor]="profileMenu">account_circle</i>

        <mat-menu #profileMenu="matMenu" class="profile-menu">
          <div class="profile-info">
            <div class="full-name">{{user?.ifs_givenname}} {{user?.ifs_surname}}</div>
            <div class="email">{{user?.ifs_email}}</div>
            <div class="airline">{{user?.airlineCode}}</div>
          </div>

          <button class="menu-btn-white" (click)="logout()" mat-menu-item>Your Account Details</button>
          <button class="menu-btn-primary" (click)="logout()" mat-menu-item>LOGOUT</button>
        </mat-menu>
      </li>
    </ul>
  </nav>
  <div class="main-content">
    <div class="router-div">
      <app-header-component></app-header-component>
    </div>
  </div>
</div>
<!-- <div>
    <app-header-component></app-header-component>
  </div>
  <div class="container-fluid app-container px-0">
    <router-outlet></router-outlet>
  </div>

  <notifier-container></notifier-container> -->