import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { FileUploadDialogComponent } from '../file-upload-dialog/file-upload-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { UploadDialogComponent } from '../upload-dialog/upload-dialog.component';
import { MatTabGroup } from '@angular/material/tabs';
@Component({
  selector: 'app-header-component',
  templateUrl: './header-component.component.html',
  styleUrls: ['./header-component.component.scss']
})
export class HeaderComponentComponent implements OnInit {

  disableUploadBtn: any;
  showUploadBanner:boolean=false;
  videos: any[] = [];
  filteredVideos: any[] = [];
  selectedTabIndex: number = 0;

  constructor(private router: Router,public dialog: MatDialog,private sharedDataService:SharedDataService ) { }
  @ViewChild(MatTabGroup) tabGroup!: MatTabGroup;

  ngOnInit(): void {
    this.sharedDataService.showUploadBanner$.subscribe(showUploadBanner=>{
      this.showUploadBanner=showUploadBanner;
    })

    this.sharedDataService.currentTabIndex$.subscribe(index => {
      console.log("Navigate to other media tab:",index)
      this.tabGroup.selectedIndex = index;
    });
  }

  onTabChange(event: any) {
    this.selectedTabIndex = event.index;
  }
  
  searchArtifacts(event: KeyboardEvent) {
    const searchTerm = (event.target as HTMLInputElement).value;
    this.sharedDataService.updateFilterValue(searchTerm);
  }

  openFileUploadDialog() {
    console.log("Open File Upload Dialog");
    console.log("window.innerHeight : ", window.innerHeight);
    let heightRatio = 0;
    let widthRatio = 0;
    if (window.innerHeight > 900) {
      heightRatio = 7;
      widthRatio = 2.5;
    } else {
      heightRatio = 11;
      widthRatio = 3.5;
    }
    const windowHeight = (window.innerHeight * heightRatio) / 100;
    const windowWidth = (window.innerWidth * widthRatio) / 100;
    const dialogRef = this.dialog.open(UploadDialogComponent, {
      width: `${windowWidth}%`,
      height: `${windowHeight}%`,
      data: {},
      autoFocus: false
    });
  }
  

}
