<div class="status-message" *ngIf="showUploadBanner">
    <lib-status-message></lib-status-message>
  </div>
<div class="header-fixed header-vm">
    <p class="training">TRAINING</p>
    <p class="video-library">Artifact Service</p> 
    <div class="search-bar-div">
        <div>
            <input matInput (keyup)="searchArtifacts($event)" placeholder="Search" class="search-input">
            <i class="material-icons search-magnifier">search</i>
        </div>
        <div class="action-button">
            <button mat-raised-button
                    class="upload-btn"
                    [disabled]="disableUploadBtn"
                    (click)="openFileUploadDialog()"
                    id="action-dropdown-menu-upload-file"
            >
                <span class="mat-button-wrapper">
                    <mat-icon class ="btn-icon-upload">upload_file</mat-icon>UPLOAD
                </span>
            </button>
         </div>
     </div>
    <mat-tab-group class="w-100" (selectedTabChange)="onTabChange($event)">
        <mat-tab label="VIDEOS">
            <ng-container *ngIf="selectedTabIndex === 0">
                <app-main-video-list [docType]="['TSVIDEO','FTVIDEO','MTVIDEO']"></app-main-video-list>
            </ng-container>
        </mat-tab>
        <mat-tab label="OTHER MEDIA">
            <ng-container *ngIf="selectedTabIndex === 1">
                <app-main-video-list [docType]="['MTSPKG']"></app-main-video-list>
            </ng-container>
        </mat-tab>
    </mat-tab-group>
</div>

